import React from 'react';
import { MsalProvider } from "@azure/msal-react";
import AppNBCProvider from './AppNBC.Provider';
import AppInner from './AppInner';


const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <AppNBCProvider>
        <AppInner />
      </AppNBCProvider>
    </MsalProvider>
);
}
export default App;