/*eslint unicode-bom: ["error", "always"]*/
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarDays, faShield } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useEffect, useState } from "react";
import { Col, Nav, Row} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { NotificationContainer } from 'react-notifications';
import api from "../../services/api";
import AuthService from "../../services/auth.service";
import ChatPanel from "../Chat/ChatPanel";
import CardPost from "./CardPost";
import './GridPost.css';
import Post from "./Post";
import ShowFoldersModal from '../ReleatedCrimes/Folders/RelatedCrimeFolderModal';
import AddRelateCrimeModal from '../ReleatedCrimes/Folders/AddRelateCrimeModal';
import Pagination from '../Common/pagination';

library.add(faCalendarDays, faShield);
const GridPost = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [userRoles, setUserRoles] = useState(AuthService.getRoles());

    const [userlogged, setUserLogged] = useState([]);
    const [agenciesuser, setAgenciesUser] = useState([]);
    const [listPostFeed, setListPostFeed] = useState([]);
    const [listPostBolos, setListPostBolos] = useState([]);
    const [listPostOfficer, setListPostOfficer] = useState([]);
    const [listPostAttempt, setListPostAttempt] = useState([]);
    const [listPostRelated, setListRelated] = useState([]);
    const [openCreatePost, setOpenCreatePost] = useState(false);
    const [listfolders, setListFolders] = useState([]);
    const [notficationPost, setNotficationPost] = useState({});
    const [smsNotification, setSmsNotification] = useState(false);
    const [emailNotification, setEmailNotification] = useState(false);
    const [loadingPosts, setLoadingPosts] = useState(true);
    const [isAfterDelete, setIsAfterDelete] = useState(false);
    const [isAfterCreatedPost, setIsAfterCreatedPost] = useState(false);
    const [isAfterSaveRelateCrime, setIsAfterSaveRelateCrime] = useState(false);
    const [isAfterRemoveRelateCrime, setIsAfterRemoveRelateCrime] = useState(false);
    
    const [modalDelete, setModalDelete] = useState({
        show: false,
        id: "",
        name: ""
    });

    const toggleDelete = (incident) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: incident,
            name: incident.incidentNum
        });

    }

    const DeletePost = (id) => {
        const formDatadelete = new FormData();

        formDatadelete.append("id", id);

        api.post("/incidents/delete", formDatadelete, AuthService.getToken())
            .then(response => {
                if (response.data) {
                    toggleDelete(false);
                    setIsAfterDelete(true);
                }
            });
    }

    const [chatMessage, setChatMessage] = useState({});

    const [currentTab, setCurrentTab] = useState("first");

    const handleSelect = (eventKey) => {
        setChatMessage({
            From: "",
            To: [eventKey.userId],
            Message: "",
            // Chat_Id: 0,
            IncidentNumber: eventKey.incidentNum,
            IncidentId: eventKey.id,
            chat: [],
            Chat_Id: eventKey.Chat_Id
        });
        setCurrentTab("five");
    };

    if (props !== undefined && props.location !== undefined && props.location.state !== undefined && currentTab !== "five") {
        handleSelect(props.location.state);
        setCurrentTab("five");

    }

    const SearchPostRelated = (item) => {
        if (item == null) {
            item = { id:0}
        }
        api.get(
            "/releatedcrimes/IncidentsFolder?folderid=" + item.id
            , AuthService.getToken())
            .then((response) => {
                setListRelated(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const ReloadRelated = () => {
        ListFolder();
        GetIncidentsFolders();
    }
    const ListFolder = () => {
        api.get(
            "/releatedcrimes/folders"
            , AuthService.getToken())
            .then((response) => {
                setListFolders(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [isParentPagination, setIsParentPagination] = useState(true);
    const [isParentPaginationShown, setIsParentPaginationShown] = useState(true);
    const [isExecuteByCurrentPage, setIsExecuteByCurrentPage] = useState(false);
    const [isExecuteInitialLoad, setIsExecuteInitialLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    const onCurrentPageChange = useCallback((pageNum) => {
        if(isExecuteInitialLoad === true) {
            setCurrentPage(pageNum);
            setIsExecuteByCurrentPage(true);
        }
    },[isExecuteInitialLoad]);

    const onParentPaginationChange = (val) => {
        setIsParentPagination(val);
    }

    const SearchPost = () => {
        setLoadingPosts(true);
        api.get(`/incidents/list/get?page=${currentPage}&take=7`, AuthService.getTokenJson())
            .then((response) => {
                let listfeed = [];
                let listbolos = [];
                let listattemptid = [];
                let listofficersafety = [];

                response.data.data.forEach((element) => {

                    if (element.incidentCategory.name.toUpperCase() === "BOLOS")
                        listbolos.push(element);
                    if (element.incidentCategory.name.toUpperCase() === "OFFICER SAFETY")
                        listofficersafety.push(element);
                    if (element.incidentCategory.name.toUpperCase() === "ATTEMPT TO ID")
                        listattemptid.push(element);
                   
                        listfeed.push(element);
                });


                setListPostFeed(listfeed);
                setListPostBolos(listbolos);
                setListPostOfficer(listofficersafety);
                setListPostAttempt(listattemptid);
                setLoadingPosts(false);
                setIsExecuteByCurrentPage(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const AfterSaveRelateCrime = () => {
        setIsAfterSaveRelateCrime(true);
    }

    const AfterRemoveRelateCrime = () => {
        setIsAfterRemoveRelateCrime(true);
    }

    useEffect(() => {
        api.get("/agency/myagencies", AuthService.getTokenJson())
            .then((response) => {
                setAgenciesUser(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setAgenciesUser]);

    useEffect(() => {
        let userauth = AuthService.getCurrentUser();
        setUserLogged(userauth);
    }, [setUserLogged]);

    useEffect(() => {
        loadIncidents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setListPostFeed, setListPostBolos, setListPostOfficer, setListPostAttempt]);

    const loadIncidents = useCallback(() => {
        api.get(`/incidents/list/get?page=${currentPage}&take=7`, AuthService.getTokenJson())
            .then((response) => {
                let listfeed = [];
                let listbolos = [];
                let listattemptid = [];
                let listofficersafety = [];
                const { totalPages, data, totalData } =  response.data;

                setPageData({
                    isLoading: false,
                    rowData: data,
                    totalPages,
                    totalData,
                });

                response.data.data.forEach((element) => {

                    if (element.incidentCategory.name.toUpperCase() === "BOLOS")
                        listbolos.push(element);
                    if (element.incidentCategory.name.toUpperCase() === "OFFICER SAFETY")
                        listofficersafety.push(element);
                    if (element.incidentCategory.name.toUpperCase() === "ATTEMPT TO ID")
                        listattemptid.push(element);

                    listfeed.push(element);
                });
                setListPostFeed(listfeed);
                setListPostBolos(listbolos);
                setListPostOfficer(listofficersafety);
                setListPostAttempt(listattemptid);
                setLoadingPosts(false);
                setIsExecuteByCurrentPage(false);
                setIsExecuteInitialLoad(true);
            })
            .catch((error) => {
                console.log(error);
            });

        api.get(
            "/releatedcrimes/IncidentsFolder?folderid=0"
            , AuthService.getToken())
            .then((response) => {
                setListRelated(response.data);

            })
            .catch((error) => {
                console.log(error);
            })
    },[currentPage])

    useEffect(() => {
        if(isExecuteByCurrentPage === true) {
            setLoadingPosts(true);
            loadIncidents();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage,isExecuteByCurrentPage]);

    //Modals

    const [folderModal, setFolderModal] = useState(false);
    const [folderModalData, setFolderModalData] = useState();
    const [relateCrimeModal, setRelateCrimeModal] = useState(false);
    const [relateCrimeData, SetRelateCrimeData] = useState({});
    const [sendNotificationModal, setSendNotificationModal] = useState(false);
    const closeFolders = (cleanData) => {

        setFolderModal(false);

        if (cleanData === undefined)
            setFolderModalData(undefined);

        
    }
    const closeRelateCrime = () => {
        setRelateCrimeModal(false);
    }

    const saveRelateCrime = () => {
        setRelateCrimeModal(false);
    }
    const GetIncidentsFolders = () => {
        SearchPostRelated(null);
    }
    const OpenRelateCrime = (item) => {
        SetRelateCrimeData(item);
        setFolderModal(false);
        setRelateCrimeModal(true);
    }

    const OpenFolders = (e, item) => {
        e.preventDefault();
        setFolderModal(true);
        setFolderModalData(item);
    }

    const SetNotification = (incident) => {
        setNotficationPost(incident);
        setSendNotificationModal(true);
        getSettingsNotification(incident);
    }


    const closeNotificationModal = () => {
        setSendNotificationModal(false);
        
    }

    const getSettingsNotification = (incident) => {
        api.get("/incidents/getIncidentNotification?incident=" + incident.id, AuthService.getToken())
            .then((result) =>
            {
                if (result && result.data !== "") {
                    setSmsNotification(result.data.sms);
                    setEmailNotification(result.data.email);
                } else {
                    setSmsNotification(false);
                    setEmailNotification(false);
                }
                
        });
    }

    const SendDataNotification = () => {
        api.post("/incidents/setNotification", { IncidentId: notficationPost.id, Sms: smsNotification, Email: emailNotification } , AuthService.getTokenPost())
            .then(response => {
            });
        setSendNotificationModal(false);

    }

    const OnsmsNotificationChange = (e) => {
        setSmsNotification(e.target.checked);
    }

    const OnemailNotificationChange = (e) => {
        setEmailNotification(e.target.checked);
    }

    //my post

    // eslint-disable-next-line no-unused-vars
    const [isLoading, setIsLoading] = useState(true);
    const [myPosts, setMyPost] = useState([]);
    const SearchMyPost = (x, y, z, draft, published, removed) => {
        if (x === undefined) {
            x = "";
        }
        if (y === undefined) {
            y = "";
        }
        if (z === undefined) {
            z = "";
        }
        if (draft === undefined) {
            draft = true;
        }
        if (published === undefined) {
            published = true;
        }
        if (removed === undefined) {
            removed = true;
        }
        setIsLoading(true);
        api.get(
            "/incidents/mypost?dstart=" + x + "&dend=" + y + "&search=" + z + "&draft=" + draft + "&published=" + published + "&removed=" + removed
        )
            .then((response) => {
                let listfeed = [];
                response.data.forEach((element) => {

                    listfeed.push(element);
                });
                setMyPost(listfeed);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        SearchMyPost();
        ListFolder();
    }, [setMyPost,setListFolders]);

    // my department posts 
   
    const [myDepartMentPosts, setMyDepartmentPost] = useState([]);

    //This will work on initial load of page only, text search functionality will be handle under CardPost component
    const SearchMyDepartMenPost = (x, y, z, draft, published, removed) => {
        if (x === undefined) {
            x = "";
        }
        if (y === undefined) {
            y = "";
        }
        if (z === undefined) {
            z = "";
        }
        if (draft === undefined) {
            draft = false;
        }
        if (published === undefined) {
            published = true;
        }
        if (removed === undefined) {
            removed = true;
        }

        setIsLoading(true);
        
        api.get(
            "/incidents/incidentsdepartment?dstart=" + x + "&dend=" + y + "&search=" + z + "&draft=" + draft + "&published=" + published + "&removed=" + removed
        )
            .then((response) => {
                let listMyDepartment = [];
                response.data.forEach((element) => {

                    listMyDepartment.push(element);
                });

                setMyDepartmentPost(listMyDepartment);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    }

    const onNavItemSelectChange = useCallback((tab) => {
        if(isParentPagination === true) {
            setIsParentPaginationShown(tab === "first");
        }
    },[isParentPagination]);

    useEffect(() => {
        SearchMyDepartMenPost();

    }, [setMyDepartmentPost, setIsLoading]);

    useEffect(() => {
        if(isAfterCreatedPost) {
            SearchMyDepartMenPost();
            SearchMyPost();
            setIsAfterCreatedPost(false);
        }
    }, [isAfterCreatedPost, setIsLoading]);

    useEffect(() => {
        if(isAfterDelete === true) {
            loadIncidents();
            SearchMyPost();
            SearchMyDepartMenPost();
            setIsAfterDelete(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAfterDelete]);

    useEffect(() => {
        if(isAfterSaveRelateCrime) {
            loadIncidents();
            SearchMyDepartMenPost();
            SearchMyPost();
            setIsAfterSaveRelateCrime(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAfterSaveRelateCrime]);

    
    useEffect(() => {
        if(isAfterRemoveRelateCrime) {
            loadIncidents();
            SearchMyDepartMenPost();
            SearchMyPost();
            setIsAfterRemoveRelateCrime(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAfterRemoveRelateCrime]);



    //DJTR : Temporarily remove, too many side effects
    /*useEffect(() => {
        window.addEventListener("beforeunload", () => {
            console.log("CLEAR SESSION WHEN WINDOW CLOSE");
            localStorage.removeItem("user")
        });
    }, []);*/

    return (
        <>

            <Tab.Container onSelect={(key) => setCurrentTab(key)} defaultActiveKey="first" activeKey={currentTab}>
                <Row>
                    <Col lg={12}>
                        <h3 className='legendpost' >Live Feed</h3>
                    </Col>
                </Row>
                <Row className="elementBorderBottom">
                    <Col className="colWithOutMPadding">
                        <Nav variant="pills" className="flex-column tabNavItem" onSelect={onNavItemSelectChange}>
                            <Nav.Item>
                                <Nav.Link eventKey="first">All Posts</Nav.Link>
                            </Nav.Item>
                            {
                            userRoles.length > 0 && userRoles.includes("SuperAdmin") ?
                            (<></>)
                            : ( <Nav.Item>
                                    <Nav.Link eventKey="myPost">My Posts</Nav.Link>
                                </Nav.Item>)
                            }

                            {
                            userRoles.length > 0 && userRoles.includes("SuperAdmin") ?
                            (<></>)
                            : ( <Nav.Item>
                                    <Nav.Link eventKey="myDepartment">My Department's Posts</Nav.Link>
                                </Nav.Item>)
                            }
                            <Nav.Item>
                                <Nav.Link eventKey="second">BOLOS</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Attempt to ID</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fourd">Officer Safety</Nav.Link>
                            </Nav.Item>
                            <Nav.Item >
                                <Nav.Link eventKey="related">Saved Posts</Nav.Link>
                            </Nav.Item>


                        </Nav>
                    </Col>
                    <Col lg={2} className="colWithOutMPadding" >
                        {userlogged != null && userlogged.roles != null && !userlogged.roles.includes("SuperAdmin") &&
                            <button className='btn btn-primary btncreate' onClick={
                            () => {
                                setOpenCreatePost(!openCreatePost);
                            }
                            }>Create Post</button>
                        }

                    </Col>
                   
                </Row>
              <br/>
                <Row>
                    
                    <Col sm={12}>
                        <Tab.Content >
                            <Tab.Pane eventKey="first">
                                <div className="container" id="firstDiv">
                                    <CardPost setLoadingPosts={setLoadingPosts} loadingPosts={loadingPosts} SetNotfication={SetNotification}  Key={"All"} IsAfterDelete={isAfterDelete} DeleteIncident={toggleDelete} LoadIncidents={loadIncidents} OpenFolders={OpenFolders} listagenciesid={agenciesuser} openMessage={handleSelect} listpost={listPostFeed} reloadData={SearchPost} setIsParentPagination={onParentPaginationChange} AfterRemoveRelateCrime={AfterRemoveRelateCrime} ></CardPost>
                                </div>

                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className="container" id="secondDiv">
                                    <CardPost setLoadingPosts={setLoadingPosts} loadingPosts={loadingPosts} SetNotfication={SetNotification}   Key={"Bolos"}  IsAfterDelete={isAfterDelete} DeleteIncident={toggleDelete}  LoadIncidents={loadIncidents} OpenFolders={OpenFolders} listagenciesid={agenciesuser} openMessage={handleSelect} listpost={listPostBolos} reloadData={SearchPost} setIsParentPagination={onParentPaginationChange} AfterRemoveRelateCrime={AfterRemoveRelateCrime} ></CardPost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div className="container" id="thirdDiv">

                                    <CardPost setLoadingPosts={setLoadingPosts} loadingPosts={loadingPosts} SetNotfication={SetNotification}   Key={"AttempttoId"} IsAfterDelete={isAfterDelete} listfolders={listfolders} DeleteIncident={toggleDelete}  LoadIncidents={loadIncidents} OpenFolders={OpenFolders} listagenciesid={agenciesuser} openMessage={handleSelect} listpost={listPostAttempt} reloadData={SearchPost} setIsParentPagination={onParentPaginationChange} AfterRemoveRelateCrime={AfterRemoveRelateCrime} ></CardPost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourd">
                                <div className="container" id="fourthDiv">
                                    <CardPost setLoadingPosts={setLoadingPosts} loadingPosts={loadingPosts} SetNotfication={SetNotification}  Key={"OfficerSafety"} IsAfterDelete={isAfterDelete} listfolders={listfolders} DeleteIncident={toggleDelete}  LoadIncidents={loadIncidents} OpenFolders={OpenFolders} listagenciesid={agenciesuser} openMessage={handleSelect} listpost={listPostOfficer} reloadData={SearchPost} setIsParentPagination={onParentPaginationChange} AfterRemoveRelateCrime={AfterRemoveRelateCrime} ></CardPost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="myPost">
                                <div className="container" id="myPostDiv">
                                    <CardPost setLoadingPosts={setLoadingPosts} loadingPosts={loadingPosts} SetNotfication={SetNotification}   Key={"MyPost"} IsAfterDelete={isAfterDelete} myPosts={true} listfolders={listfolders} DeleteIncident={toggleDelete} LoadIncidents={SearchMyPost} OpenFolders={OpenFolders} listagenciesid={agenciesuser} openMessage={handleSelect} listpost={myPosts} reloadData={SearchPost} setIsParentPagination={onParentPaginationChange} AfterRemoveRelateCrime={AfterRemoveRelateCrime} ></CardPost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="related" >
                                <div className="container" id="relatedDiv">
                                    <CardPost setLoadingPosts={setLoadingPosts} loadingPosts={loadingPosts} SetNotfication={SetNotification}  Key={"related"} IsAfterDelete={isAfterDelete} keytype='related' listfolders={listfolders} SearchPostRelated={SearchPostRelated} DeleteIncident={toggleDelete} LoadIncidents={loadIncidents} OpenFolders={OpenFolders} listagenciesid={agenciesuser} openMessage={handleSelect} listpost={listPostRelated} reloadData={SearchPostRelated} setIsParentPagination={onParentPaginationChange} AfterRemoveRelateCrime={AfterRemoveRelateCrime} ></CardPost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="myDepartment">
                                <div className="container" id="myDepartmentDiv">
                                    <CardPost setLoadingPosts={setLoadingPosts} loadingPosts={loadingPosts} SetNotfication={SetNotification}  Key={"myDepartment"} IsAfterDelete={isAfterDelete} listfolders={listfolders} DeleteIncident={toggleDelete} LoadIncidents={SearchMyDepartMenPost} OpenFolders={OpenFolders} listagenciesid={agenciesuser} openMessage={handleSelect} listpost={myDepartMentPosts} reloadData={SearchMyDepartMenPost} setIsParentPagination={onParentPaginationChange} AfterRemoveRelateCrime={AfterRemoveRelateCrime} ></CardPost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="five">
                                <div className="container">
                                    <ChatPanel ChatMessageItem={chatMessage} />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>

                { isParentPagination === true ? 
                    (<Row>
                        <div className={isParentPaginationShown ? "" : "hidden"} >
                            <Pagination
                                totalRows={pageData.totalData}
                                pageChangeHandler={onCurrentPageChange}
                                rowsPerPage={7}
                            />
                        </div>
                    </Row>) : (<></>)
                }
              <br/>
             
            </Tab.Container>
            <Modal size="lg" isOpen={modalDelete.show}>
                <ModalHeader>
                    Delete Post
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to delete this post?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => DeletePost(modalDelete.id)}
                    >
                        Delete
                    </Button>
                    {' '}
                    <Button onClick={toggleDelete}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <NotificationContainer />
            <ShowFoldersModal ReloadRelated={ReloadRelated}  LoadIncidents={loadIncidents} AfterSaveRelateCrime={AfterSaveRelateCrime} OpenRelateCrime={OpenRelateCrime} closeFolders={closeFolders} show={folderModal} datamodel={folderModalData} />
            <AddRelateCrimeModal ReloadRelated={ReloadRelated} save={saveRelateCrime} closeRelateCrime={closeRelateCrime} show={relateCrimeModal} data={{ data: relateCrimeData === undefined ? { id: 0 } : relateCrimeData, post: folderModalData }} />
            <Modal size="xl" isOpen={openCreatePost}>
                <Post setOpenCreatePost={setOpenCreatePost} reloadData={SearchPost} setIsAfterCreatedPost={setIsAfterCreatedPost}/>
            </Modal>

            <Modal size="mb" isOpen={sendNotificationModal}>
                <ModalHeader toggle={closeNotificationModal} cssModule={{ 'modal-title': 'w-100 text-center' }}>

                            Get Notified

                </ModalHeader>
                <ModalBody>
                    <center>
                        <label>Get notified when the post is updated</label>
                        <hr/>
                        <br/>
                        <div className="row">
                            <div className="col-2 toggle-switch">
                                <div className="toggle-switch">
                                    <input id="email" type="checkbox" onChange={OnemailNotificationChange} checked={emailNotification} className="toggle-switch-1" />
                                    <label for="email"></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="labelText">Notify me by email</label>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-2 toggle-switch">
                                <div className="toggle-switch">
                                    <input id="sms" type="checkbox" onChange={OnsmsNotificationChange} checked={smsNotification} className="toggle-switch-1" />
                                    <label for="sms"></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="labelText">Notify me by SMS</label>
                            </div>
                        </div>
                    </center>
                    <Row>
                    </Row>
                </ModalBody>
                <ModalFooter className="footerBlock" cssModule={{ 'modal-title': 'w-100 text-rigth' }}>

                    <button onClick={SendDataNotification} className="btn btn-primary">Ok</button>
                </ModalFooter>
            </Modal>
            
        </>
    );
};
export default GridPost;