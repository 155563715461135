import React, {useEffect, useState, useCallback} from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarDays, faShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Nav, Row} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { NotificationContainer } from 'react-notifications';
import { useMsal } from "@azure/msal-react";
import AppNBCProvider, { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

import "./AzureHome.css"
import Pagination from "../../../components/Common/pagination";


const AzureHome = () => {

    const appNBCContext = UsedAppNBCContext();
   // eslint-disable-next-line no-unused-vars
   const { error, execute } = useFetchWithMsal();

    const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
    const [userlogged, setUserLogged] = useState([]);
    const [agenciesuser, setAgenciesUser] = useState([]);
    const [listPostFeed, setListPostFeed] = useState([]);
    const [listPostBolos, setListPostBolos] = useState([]);
    const [listPostOfficer, setListPostOfficer] = useState([]);
    const [listPostAttempt, setListPostAttempt] = useState([]);
    const [listPostRelated, setListRelated] = useState([]);
    const [openCreatePost, setOpenCreatePost] = useState(false);
    const [listfolders, setListFolders] = useState([]);
    const [notficationPost, setNotficationPost] = useState({});
    const [smsNotification, setSmsNotification] = useState(false);
    const [emailNotification, setEmailNotification] = useState(false);
    const [loadingPosts, setLoadingPosts] = useState(true);
    const [isAfterDelete, setIsAfterDelete] = useState(false);
    const [isAfterCreatedPost, setIsAfterCreatedPost] = useState(false);
    const [isAfterSaveRelateCrime, setIsAfterSaveRelateCrime] = useState(false);
    const [isAfterRemoveRelateCrime, setIsAfterRemoveRelateCrime] = useState(false);
    
    const [modalDelete, setModalDelete] = useState({
        show: false,
        id: "",
        name: ""
    });

    const toggleDelete = (incident) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: incident,
            name: incident.incidentNum
        });

    }

    // const DeletePost = (id) => {
    //     const formDatadelete = new FormData();

    //     formDatadelete.append("id", id);

    //     api.post("/incidents/delete", formDatadelete, AuthService.getToken())
    //         .then(response => {
    //             if (response.data) {
    //                 toggleDelete(false);
    //                 setIsAfterDelete(true);
    //             }
    //         });
    // }

    const [chatMessage, setChatMessage] = useState({});

    const [currentTab, setCurrentTab] = useState("first");

    const handleSelect = (eventKey) => {
        setChatMessage({
            From: "",
            To: [eventKey.userId],
            Message: "",
            // Chat_Id: 0,
            IncidentNumber: eventKey.incidentNum,
            IncidentId: eventKey.id,
            chat: [],
            Chat_Id: eventKey.Chat_Id
        });
        setCurrentTab("five");
    };

    const SearchPostRelated = useCallback((item) => {
        if(appNBCContext.dbUserId !== null) {
            if (item == null) {
                item = { id:0}
            }
    
            let _requestBody = {
                Take: item,
                Page: 1,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }
    
            //load data of incident folder
            execute("PUT", protectedEndPointAPI.home.incident.getfolderlist, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) setListRelated(_data.data);
            });
        }
    },[execute,appNBCContext])

    const ReloadRelated = () => {
        ListFolder();
        GetIncidentsFolders();
    }

    const ListFolder = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                Take: 1,
                Page: 1,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }

            //load data of incident folder
            execute("PUT", protectedEndPointAPI.home.folder.getlist, _requestBody).then((response) => {
                    var _data = response.data;
                    if(_data !== null) setListFolders(_data.data);
            });
        }
    },[execute,appNBCContext])

    const [isParentPagination, setIsParentPagination] = useState(true);
    const [isParentPaginationShown, setIsParentPaginationShown] = useState(true);
    const [isExecuteByCurrentPage, setIsExecuteByCurrentPage] = useState(false);
    const [isExecuteInitialLoad, setIsExecuteInitialLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    const onCurrentPageChange = useCallback((pageNum) => {
        if(isExecuteInitialLoad === true) {
            setCurrentPage(pageNum);
            setIsExecuteByCurrentPage(true);
        }
    },[isExecuteInitialLoad]);

    const onParentPaginationChange = (val) => {
        setIsParentPagination(val);
    }

    const SearchPost = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            setLoadingPosts(true);

            let _requestBody = {
                Take: 7,
                Page: currentPage,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }

            //load data of incident
            execute("PUT", protectedEndPointAPI.home.incident.getlistbypage, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) {
                    let listfeed = [];
                    let listbolos = [];
                    let listattemptid = [];
                    let listofficersafety = [];
                    const { totalPages, data, totalData } =  _data;

                    setPageData({
                        isLoading: false,
                        rowData: data,
                        totalPages,
                        totalData,
                    });

                    data.forEach((element) => {
                        if (element.incidentCategory.name.toUpperCase() === "BOLOS")
                            listbolos.push(element);
                        if (element.incidentCategory.name.toUpperCase() === "OFFICER SAFETY")
                            listofficersafety.push(element);
                        if (element.incidentCategory.name.toUpperCase() === "ATTEMPT TO ID")
                            listattemptid.push(element);
    
                        listfeed.push(element);
                    });
                    setListPostFeed(listfeed);
                    setListPostBolos(listbolos);
                    setListPostOfficer(listofficersafety);
                    setListPostAttempt(listattemptid);
                    setLoadingPosts(false);
                    setIsExecuteByCurrentPage(false);
                }
            });
        }
    },[execute,appNBCContext,currentPage])

    const AfterSaveRelateCrime = () => {
        setIsAfterSaveRelateCrime(true);
    }

    const AfterRemoveRelateCrime = () => {
        setIsAfterRemoveRelateCrime(true);
    }

    //[Start] - Unused list of agencies - for deletion
    // useEffect(() => {
    //     api.get("/agency/myagencies", AuthService.getTokenJson())
    //         .then((response) => {
    //             setAgenciesUser(response.data);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }, [setAgenciesUser]);
    //[End]

    //[Start] - It is used for retrieving user roles only - for deletion
    // useEffect(() => {
    //     let userauth = AuthService.getCurrentUser();
    //     setUserLogged(userauth);
    // }, [setUserLogged]);
     //[End]

     const loadIncidents = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                Take: 1,
                Page: currentPage,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }

            //load data of incident
            execute("PUT", protectedEndPointAPI.home.incident.getlistbypage, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) {
                    let listfeed = [];
                    let listbolos = [];
                    let listattemptid = [];
                    let listofficersafety = [];
                    const { totalPages, data, totalData } =  _data;

                    setPageData({
                        isLoading: false,
                        rowData: data,
                        totalPages,
                        totalData,
                    });

                    data.forEach((element) => {
                        if (element.incidentCategory.name.toUpperCase() === "BOLOS")
                            listbolos.push(element);
                        if (element.incidentCategory.name.toUpperCase() === "OFFICER SAFETY")
                            listofficersafety.push(element);
                        if (element.incidentCategory.name.toUpperCase() === "ATTEMPT TO ID")
                            listattemptid.push(element);
    
                        listfeed.push(element);
                    });
                    setListPostFeed(listfeed);
                    setListPostBolos(listbolos);
                    setListPostOfficer(listofficersafety);
                    setListPostAttempt(listattemptid);
                    setLoadingPosts(false);
                    setIsExecuteByCurrentPage(false);
                    setIsExecuteInitialLoad(true);
                        
                }
            });

            _requestBody = {
                ..._requestBody,
                FolderId: 0
            }

            //load data of incident folder
            execute("PUT", protectedEndPointAPI.home.incident.getfolderlist, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) setListRelated(_data.data);
            });

        }

    },[currentPage,appNBCContext,execute])

     useEffect(() => {
        //load initial data on 1st render
        if(!isInitialLoadDone) {
            loadIncidents();
            setIsInitialLoadDone(true);
        }
      
    }, [loadIncidents,isInitialLoadDone]);

    useEffect(() => {
        if(isExecuteByCurrentPage === true) {
            setLoadingPosts(true);
            loadIncidents();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage,isExecuteByCurrentPage]);

     //Modals
    const [folderModal, setFolderModal] = useState(false);
    const [folderModalData, setFolderModalData] = useState();
    const [relateCrimeModal, setRelateCrimeModal] = useState(false);
    const [relateCrimeData, SetRelateCrimeData] = useState({});
    const [sendNotificationModal, setSendNotificationModal] = useState(false);

    const closeFolders = (cleanData) => {

        setFolderModal(false);

        if (cleanData === undefined) setFolderModalData(undefined);
    }

    const closeRelateCrime = () => {
        setRelateCrimeModal(false);
    }

    const saveRelateCrime = () => {
        setRelateCrimeModal(false);
    }
    const GetIncidentsFolders = () => {
        SearchPostRelated(null);
    }
    const OpenRelateCrime = (item) => {
        SetRelateCrimeData(item);
        setFolderModal(false);
        setRelateCrimeModal(true);
    }

    const OpenFolders = (e, item) => {
        e.preventDefault();
        setFolderModal(true);
        setFolderModalData(item);
    }

    const SetNotification = (incident) => {
        setNotficationPost(incident);
        setSendNotificationModal(true);
        getSettingsNotification(incident);
    }

    const closeNotificationModal = () => {
        setSendNotificationModal(false);
    }

    const getSettingsNotification = useCallback((incident) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                Take: 1,
                Page: 1,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }

            //load data of incident notification
            execute("PUT", protectedEndPointAPI.home.incident.notification.getlistbyid, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) {
                    setSmsNotification(_data.sms);
                    setEmailNotification(_data.email);
                } else {
                    setSmsNotification(false);
                    setEmailNotification(false);
                }
            });
        }
    },[execute,appNBCContext])


    const SendDataNotification = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                IncidentId: notficationPost.id,
                IsSMS: smsNotification,
                IsEmail: emailNotification
            }

            //set incident notification
            execute("POST", protectedEndPointAPI.home.incident.notification.set, _requestBody).then((response) => {
                var _data = response.data;
                console.log(_data); //added temp
            });


        }
    },[execute,appNBCContext,notficationPost,smsNotification,emailNotification])

    
    useEffect(() => {console.log("home - msal error: ",error)},[error]); //added temp

    return (
        <>
            HOME
        </>
    );
}


export default AzureHome;
