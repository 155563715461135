import { protectedResources } from "../../../authConfig";

export const protectedEndPointAPI = {
    base: protectedResources.api.endpoint,

    get officer() {
      return {
        base: `${this.base}/officer`,
        get getuserid() {
          return `${this.base}/getuserid`;
        },
      };
    },

    get profile() {
      return {
        base: `${this.base}/profile`,
        get getinformation() {
          return `${this.base}/getinformation`;
        },
        get getdivisions() {
          return `${this.base}/getdivisions`;
        },
        get getexportdata() {
          return `${this.base}/getexportdata`;
        },
      }
    },

    get home() {
      return {
        base: `${this.base}/home`,
        get incident() {
          return {
            base: `${this.base}/incident`,
            get getlistbypage() {
              return `${this.base}/list/getbypage`;
            },
            get getfolderlist() {
              return `${this.base}/folder/list`;
            },
            get notification() {
              return { 
                base: `${this.base}/notification`,
                get getlistbyid() {
                  return `${this.base}/list/getbyid`;
                },
                get set() {
                  return `${this.base}/set`;
                },
              };
            },
          };
        },

        get folder() {
          return {
            base: `${this.base}/folder`,
            get getlist() {
              return `${this.base}/list`;
            },
          }
        }

      }
    }


  };

export const protectedVariable = {
  GoogleMapAPIKey: "AIzaSyA3KsiVMTTNXNNWrViDI8qwSYFfbRkaQI4"
}
