import React, { useEffect,useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import accountlogo from "../../../assets/account-w.png";
import dptlogo from "../../../assets/department-w.png";
import homelogo from "../../../assets/home-w.png"
import joinlogo from "../../../assets/join-w.png";
import msglogo from "../../../assets/msg-w.png";
import officelogo from "../../../assets/office-w.png";
import reportedlogo from "../../../assets/reports-w.png";

import './AzureNavMenu.css';

import { UsedAppNBCContext } from '../../AppNBC.Provider';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { protectedEndPointAPI } from '../../Helpers/ProtectedCustomClass';


const AzureNavMenu = () => {
    const { instance } = useMsal();
    const appNBCContext = UsedAppNBCContext();

    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const [url,setUrl] = useState(window.location.pathname);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const ChangeActive = (url) => {
        setUrl(url);
    }

    useEffect(() => {
        if(appNBCContext.isSuperAdmin !== null) setIsSuperAdmin(appNBCContext.isSuperAdmin)
    },[appNBCContext])
    

    return (
        <div>
            {isSuperAdmin ? 
            (
                <ul className="ulnav">
                    <NavItem  className={`navbar-tab ${url === "/home" || url === "/" ? "active" : ""}`} onClick={() => ChangeActive("/home")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/home"> <img src={homelogo} className="iconsnav" alt="Mome" />  <span className="navtext">Home</span></NavLink>

                    </NavItem>
                    <NavItem  className={`navbar-tab ${url === "/message" ? "active" : ""}`} onClick={() => ChangeActive("/message")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/message"> <img src={msglogo} className="iconsnav" alt="Message" />  <span className="navtext">Message</span></NavLink>

                    </NavItem>
                    <NavItem  className={`navbar-tab ${url === "/mydepartments" ? "active" : ""}`} onClick={() => ChangeActive("/mydepartments")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/mydepartments"> <img src={dptlogo} className="iconsnav" alt="My Departments" />  <span className="navtext">My Departments</span></NavLink>

                    </NavItem>
                    <NavItem  className={`navbar-tab ${url === "/accounts" ? "active" : ""}`} onClick={() => ChangeActive("/accounts")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/accounts"> <img src={accountlogo} className="iconsnav" alt="Accounts" /><span className="navtext">Accounts</span></NavLink>
                    </NavItem>
                    <NavItem  className={`navbar-tab ${url === "/joinrequests" ? "active" : ""}`} onClick={() => ChangeActive("/joinrequests")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/joinrequests"> <img src={joinlogo} className="iconsnav" alt="Join Requests" /><span className="navtext">Join Requests</span></NavLink>
                    </NavItem>
                    <NavItem className={`navbar-tab ${url === "/reportedposts" ? "active" : ""}`} onClick={() => ChangeActive("/reportedposts")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/reportedposts"> <img src={reportedlogo} className="iconsnav" alt="Reported Posts" /><span className="navtext">Reported Posts</span></NavLink>
                    </NavItem>
                    <NavItem className={`navbar-tab ${url === "/agencies" ? "active" : ""}`} onClick={() => ChangeActive("/agencies")}>
                        <NavLink tag={Link} className="text-dark navlink" to="/agencies"> <img src={officelogo} className="iconsnav" alt="Agencies" /><span className="navtext">Agencies</span></NavLink>
                    </NavItem>
                </ul>
            ) 
            : (<></>)
            }
        </div>
    );
}
export default AzureNavMenu;
