import React, {useEffect} from 'react';
import { Route } from 'react-router';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { LayoutNoLogged } from '../components/LayoutNoLogged';
import { LayoutLoggedIn } from './Layout/LayoutLoggedIn';
import { AzureLogin } from './Authentication/AzureLogin'
// eslint-disable-next-line no-unused-vars
import { Post, GridPost, PostInfo } from '../components/Post'; //Do not remove this since the layout of login screen will be affected
import '../custom.css'
import "@fontsource/work-sans";
import { AzureProfile } from './components/AzureProfile';
import { AzureHome } from './components/AzureHome';
import { useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';
import useFetchWithMsal from './hooks/useFetchWithMsal';
import { UsedAppNBCContext } from './AppNBC.Provider';
import { protectedEndPointAPI } from './Helpers/ProtectedCustomClass';



// const HomePage = () => {

//   return (
//     <div>THIS IS HomePage from V2 app1</div>
//   )

// }

// const AzureProfileTEST = () => {

//   return (
//     <div>THIS IS AZURE PROFILE TEMP</div>
//   )

// }

const MessagePage  = () => {

  return (
    <div>MESSAGE PAGE</div>
  )

}

const MyDepartments  = () => {

  return (
    <div>MY DEPARTMENT PAGE</div>
  )

}

const Accounts  = () => {

  return (
    <div>ACCOUNTS PAGE</div>
  )

}

const JoinRequests  = () => {

  return (
    <div>JOIN REQUEST PAGE</div>
  )

}

const ReportedPosts  = () => {

  return (
    <div>REPORTED POST PAGE</div>
  )

}

const Agencies  = () => {

  return (
    <div>AGENCIES PAGE</div>
  )

}

const AppInner = () => {

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // eslint-disable-next-line no-unused-vars
  const { error, execute } = useFetchWithMsal();

  const appNBCContext = UsedAppNBCContext();

  useEffect(() => {
    if(instance.getActiveAccount() !== null) {

      var _account = instance.getActiveAccount();
      const _userRoles = _account.idTokenClaims.roles;

      console.log("_account: ",_account); //added temp

      if(appNBCContext.dbUserId !== null) {
        if(appNBCContext.dbUserId === "") {
          execute("GET", protectedEndPointAPI.officer.getuserid + "?email=" + _account.username).then((response) => {
              var _data = response.data;
              if(_data !== null) {
                  appNBCContext.onSetDbUserId(_data);
              }
          });

          if(appNBCContext.userRoles !== null) {
              if(appNBCContext.userRoles.length <= 0)  appNBCContext.onUserRoles(_userRoles);
          }

          if(appNBCContext.isSuperAdmin !== null) {
            if(appNBCContext.isSuperAdmin !== "") appNBCContext.onSetIsSuperAdmin(_userRoles.includes("Manager") || _userRoles.includes("SuperAdmin"));
          }

          if(appNBCContext.isAdmin !== null) {
            if(appNBCContext.isAdmin !== "") appNBCContext.onSetIsAdmin(_userRoles.includes("Manager") || _userRoles.includes("Admin"));
          }

        }

      }

    }
  

  },[instance,appNBCContext,execute]);

  return (
   <>
    {isAuthenticated ? (
      <AuthenticatedTemplate>
          <LayoutLoggedIn>
          <Route exact path='/' component={AzureHome} />
            <Route exact path='/home' component={AzureHome} />
            <Route exact path='/profile' component={AzureProfile} />
            <Route exact path='/message' component={MessagePage} />
            <Route exact path='/mydepartments' component={MyDepartments} />
            <Route exact path='/accounts' component={Accounts} />
            <Route exact path='/joinrequests' component={JoinRequests} />
            <Route exact path='/reportedposts' component={ReportedPosts} />
            <Route exact path='/agencies' component={Agencies} />
            <Route exact path='/login' component={AzureLogin} />
          </LayoutLoggedIn>
      </AuthenticatedTemplate>
    ) 
    : 
    (      
    <UnauthenticatedTemplate>
      <LayoutNoLogged>
          <Route exact path='*' component={AzureLogin} />  {/**Redirect to login page when browsing non existing page**/}
          <Route exact path='/login' component={AzureLogin} />
      </LayoutNoLogged>
    </UnauthenticatedTemplate>)}
   </>
);
}
export default AppInner;