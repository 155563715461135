import React, { useContext, useState } from "react";

export const AppNBCContext = React.createContext();


export const UsedAppNBCContext = () => {
    return useContext(AppNBCContext);
};



const AppNBCProvider = ({ children }) => {

    const [dbUserId, setDbUserId] = useState("");
    const onSetDbUserId = (val) => {
        setDbUserId(val);
    }

    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const onSetIsSuperAdmin = (val) => {
        setIsSuperAdmin(val);
    }

    const [isAdmin, setIsAdmin] = useState(false);
    const onSetIsAdmin = (val) => {
        setIsAdmin(val);
    }

    const [userRoles, setUserRoles] = useState([]);
    const onUserRoles = (val) => {
        setUserRoles(val);
    }

    const props = {
        dbUserId,
        onSetDbUserId,

        isSuperAdmin,
        onSetIsSuperAdmin,

        isAdmin,
        onSetIsAdmin,

        userRoles,
        onUserRoles
    };
  
    return (
      <AppNBCContext.Provider value={props}>{children}</AppNBCContext.Provider>
    );

};


export default AppNBCProvider