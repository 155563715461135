import dotsMore from "../../assets/dotsMore.png";
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { OptionsPost } from "../Post";
import { faCalendarDays, faShield, faBars, faUserGroup, faClock, faLocationDot, faFolderOpen, faMagnifyingGlass, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileCard from '../Profile/ProfileCard';
import iconpdf from "../../assets/pdficon.png";
// import iconword from "../../assets/wordicon.svg";
import WordIcon from "../../assets/wordIcon";
import iconexcel from "../../assets/excelicon.png";
import iconpower from "../../assets/powericon.png";
import iconrarzip from "../../assets/iconrarzip.png";
import icontxt from "../../assets/txticon.png";
import iconnopreview from "../../assets/nopreview.svg";
import StatusBadge from "../Officer/StatusBadge";
library.add(faCalendarDays, faShield, faBars, faUserGroup, faClock, faLocationDot, faFolderOpen, faMagnifyingGlass, faAngleDown);

const IncidentCard = (props) =>
{

    const PreviewImage = (imgprev) => {

        if (imgprev.file.type.includes("image/")) {
            //setImageModal(true);
            //setImagePreview(imgprev);
        }
        else {
            window.open(imgprev.file.path);
        }
    }

    return <>
        <div key={props.item.id} className="row">
            <div className="col-12">
                <div key={props.item.id} className="card cardpost">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="container-img">

                                { props.item.filesIncidents && props.item.filesIncidents.length > 0 
                                    ? 
                                    (<>
                                        {props.item.filesIncidents[0].file.type === "application/pdf" &&
                                        <img key={props.item.filesIncidents[0].id} className="card-img-top" src={iconpdf} aria-hidden alt="Card image cap" />
                                        }
                                        {props.item.filesIncidents[0].file.type.includes("image/") &&
                                            <img key={props.item.filesIncidents[0].id} className="card-img-top" src={props.item.filesIncidents[0].file.path} aria-hidden alt="Card image cap" />
                                        }
                                        {props.item.filesIncidents[0].file.type.includes("officedocument.word") &&
                                            <img key={props.item.filesIncidents[0].id} className="card-img-top" src={WordIcon} aria-hidden alt="Card image cap" />
                                        }
                                        {props.item.filesIncidents[0].file.type.includes("officedocument.spreadsheetml") &&
                                            <img key={props.item.filesIncidents[0].id} className="card-img-top" src={iconexcel} aria-hidden alt="Card image cap" />
                                        }
                                        {props.item.filesIncidents[0].file.type.includes("officedocument.presentationm") &&
                                            <img key={props.item.filesIncidents[0].id} className="card-img-top" src={iconpower} aria-hidden alt="Card image cap" />
                                        }
                                        {props.item.filesIncidents[0].file.type.includes("text/plain") &&
                                            <img key={props.item.filesIncidents[0].id} className="card-img-top" src={icontxt} aria-hidden alt="Card image cap" />
                                        }

                                        {(props.item.filesIncidents[0].file.type.includes("application/x-zip-compressed") || props.item.filesIncidents[0].file.type.includes("application/octet-stream")) &&
                                            <img  key={props.item.filesIncidents[0].id} className="card-img-top" src={iconrarzip} aria-hidden alt="Card image cap" />
                                        }
                                    </>) 
                                    : 
                                    (<img  src={iconnopreview} aria-hidden alt="Card image cap" />)
                                }

                                


                            </div>
                            {/*props.item.filesIncidents.map((image) => (

                                <img key={props.item.id} className="card-img-top" src={image.file.path} aria-hidden alt="Card image cap" />
                            ))*/}
                        </div>
                        <div className="col-md-9 marginPost">
                            <div className="row">
                                <div className="col-11">
                                    <label className="textBlue" >{props.item.agencyName}</label>
                                </div>
                                <div className="col-1">
                                    <button id={`${props.Key}${props.item.id}`} className="btn btn-default btnMore"><img src={dotsMore} /></button>
                                    <UncontrolledPopover
                                        trigger="legacy"
                                        placement="bottom"
                                        target={`${props.Key}${props.item.id}`}
                                    >

                                        <PopoverBody>
                                            <OptionsPost DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents} OpenFolders={props.OpenFolders} data={props.item} OpenSharePost={(event) => props.OpenSharePost(props.item)} ></OptionsPost>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </div>
                            </div>
                            <div className="row">
                                <label className="incidenTitle">Incident: {props.item.incidentNum}</label>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <FontAwesomeIcon className="svgIconGrey" icon={faClock} />
                                    &nbsp;
                                    <span className="cardSpan">
                                        {props.item.date2}
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon className="svgIconGrey" icon={faLocationDot} />
                                    &nbsp;
                                    <span className="cardSpan">
                                        {props.item.address}
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon className="svgIconGrey" icon={faFolderOpen} />
                                    &nbsp;
                                    <span className="cardSpan">
                                        {props.item.type}
                                    </span>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className="card-text">{props.item.description}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        props.item.filesIncidents && props.item.filesIncidents.length > 1 && props.item.filesIncidents.slice(1).map((file) => {
                                            return <>
                                                <div className="thumbNailImageIncident">
                                                    {file.file.type.includes("image/") &&
                                                        <img key={file.id}
                                                            className="thumbimg" src={file.file.path} aria-hidden alt="Card image cap" />
                                                    }
                                                    {file.file.type === "application/pdf" &&
                                                        <img key={file.id} className="thumbimg" src={iconpdf} aria-hidden alt="Card image cap" />
                                                    }
                                                    {
                                                        /**[START] COMMENTED TEMP**/
                                                    }
                                                    {/* {file.file.type.includes("officedocument.word") &&
                                                        <img key={file.id} className="thumbimg" src={iconword} aria-hidden alt="Card image cap" />
                                                    } */}
                                                    {
                                                        /**[END] COMMENTED TEMP**/
                                                    }
                                                    {file.file.type.includes("officedocument.spreadsheetml") &&
                                                        <img key={file.id} className="thumbimg" src={iconexcel} aria-hidden alt="Card image cap" />
                                                    }
                                                    {file.file.type.includes("officedocument.presentationm") &&
                                                        <img key={file.id} className="thumbimg" src={iconpower} aria-hidden alt="Card image cap" />
                                                    }
                                                    {file.file.type.includes("text/plain") &&
                                                        <img key={file.id} className="thumbimg" src={icontxt} aria-hidden alt="Card image cap" />
                                                    }
                                                    {(file.file.type.includes("application/x-zip-compressed") || file.file.type.includes("application/octet-stream")) &&
                                                        <img key={file.id} className="thumbimg" src={iconrarzip} aria-hidden alt="Card image cap" />
                                                    }

                                                </div>
                                            </>

                                        })
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-11">
                                    {
                                        props.item.myPost && props.item.myPost === true &&
                                        <>
                                            <label className="lblProfile">My Posts</label>
                                        </>
                                    }

                                    {
                                        props.item.myPost === false &&
                                        <>
                                            <label className="cardSpan">
                                                {props.item.division ? props.item.division + ":" : ""}&nbsp;
                                            </label>

                                            <label className="lblProfile" id={`PopoverLegacy${props.item.id}`} >
                                                {props.item.userName} &nbsp; {StatusBadge(props.item.user.deleted ? 3 : props.item.user.appStatus === 1 ? 2 : 1 )} &nbsp;<FontAwesomeIcon className="svgIconGrey" icon={faAngleDown} />
                                            </label>

                                            <UncontrolledPopover target={`PopoverLegacy${props.item.id}`}
                                                trigger="legacy"
                                                placement="top-start"
                                            >

                                                <PopoverBody>
                                                    <ProfileCard openMessage={props.openMessage} id={props.item.userId} ></ProfileCard>
                                                </PopoverBody>

                                            </UncontrolledPopover>
                                        </>
                                    }

                                </div>
                                <div className="col-1">
                                    {
                                        props.item.canEdit && props.item.canEdit === true && <a href={"/post/edit/" + props.item.id} className="btn btn-secondary">Edit</a>
                                    }

                                </div>

                            </div>
                        </div>

                    </div>



                </div>
            </div>
        </div>
    </>

}

export default IncidentCard;