/*eslint unicode-bom: ["error", "always"]*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import './GridPost.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faShield, faBars, faUserGroup, faClock, faLocationDot, faFolderOpen, faMagnifyingGlass,faAngleDown} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { ModalBody, ModalFooter, ModalHeader, Col } from "react-bootstrap";
import { PopoverBody, UncontrolledPopover, Button, Modal, Row, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ProfileCard from '../Profile/ProfileCard';
import AuthService from "../../services/auth.service";
import api from "../../services/api";
import { GridOfficerSharePost } from "../Officer";
import {  NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import OptionsPost from './CardPost.OptionsPost';
import dotsMore from "../../assets/dotsMore.png";
import iconpdf from "../../assets/pdficon.png";
//import iconword from "../../assets/wordicon.svg";
import WordIcon from "../../assets/wordIcon";
import iconexcel from "../../assets/excelicon.png";
import iconpower from "../../assets/powericon.png";
import iconrarzip from "../../assets/iconrarzip.png";
import icontxt from "../../assets/txticon.png";
import folderpng from "../../assets/folder_dropdown.png";
import icontree from "../../assets/icontree.png";
import icontreeact from "../../assets/icontreeact.png";
import icongrid from "../../assets/icongrid.png";
import icongridact from "../../assets/icongridact.png";
import iconnopreview from "../../assets/nopreview.svg";
import CustomPicker from "../Common/CustomPicker"
import Avatar from 'react-avatar';
import ViewportList from 'react-viewport-list';
import moment from 'moment';
import { AnyPost, LoaderPost } from "../Post/StatePage";
import Pagination from '../Common/pagination';
import CardPostFilterBy from './CardPost.FilterBy';
import CardPostOfficersCard from './CardPost.OfficersCard'
import CardPostListCard from './CardPost.PostListCard'
import CardPostFileList from './CardPost.FileList'
import CardPostAgenciesList from './CardPost.AgenciesList'
import StatusBadge from "../Officer/StatusBadge";

library.add(faCalendarDays, faShield, faBars, faUserGroup, faClock, faLocationDot, faFolderOpen, faMagnifyingGlass, faAngleDown);
const CardPost = (props) => {
    let history = useHistory();
    const viewPortRef = useRef(null);
    const viewPortListRef = useRef(null);

    const { reloadData, loadingPosts, setLoadingPosts } = props;

    const infopost = props.isinfo ? props.isinfo : false;
    const [dropdownOpen, setDropDownOpen] = useState(false);
    const [listpost, setListPost] = useState([]);
    const [viewselect, setViewSelect] = useState("grid");
    const [listofficer, setListOfficer] = useState([]);
    const [listfiles, setListFiles] = useState([]);
    const [listagencies, setListAgencies] = useState([]);
    const [isSearched, setSearched] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [userlogged, setUserLogged] = useState({});

    //[Start] - All Post Tab
    const [postsfilter, setpostsfilter] = useState(true);
    const [officers, setofficers] = useState(true);
    const [files, setfiles] = useState(true);
    const [agencies, setAgencies] = useState(true);
    //[End] - All Post Tab

    //[Start] - MyPost Tab
    const [myPostsfilter, setMyPostsFilter] = useState(true);
    const [myPostsOfficers, setMyPostsOfficers] = useState(true);
    const [myPostsFiles, setMyPostsFiles] = useState(true);
    const [myPostsAgencies, setMyPostsAgencies] = useState(true);
    //[End] - MyPost Tab

    //[Start] - My Department Post Tab
    const [myDepartmentPostsFilter, setMyDepartmentPostsFilter] = useState(true);
    const [myDepartmentPostsOfficers, setMyDepartmentPostsOfficers] = useState(true);
    const [myDepartmentPostsFiles, setMyDepartmentPostsFiles] = useState(true);
    const [myDepartmentPostsAgencies, setMyDepartmentPostsAgencies] = useState(true);
    //[End] - My Department Post Tab

    //[Start] - BOLOS Tab
    const [bolosPostsfilter, setBolosPostsFilter] = useState(true);
    const [bolosOfficers, setBolosOfficers] = useState(true);
    const [bolosFiles, setBolosFiles] = useState(true);
    const [bolosAgencies, setBolosAgencies] = useState(true);
    //[End] - BOLOS Tab

    //[Start] - Attempt To Id Tab
    const [attemptToIdPostsfilter, setAttemptToIdPostsFilter] = useState(true);
    const [attemptToIdOfficers, setAttemptToIdOfficers] = useState(true);
    const [attemptToIdFiles, setAttemptToIdFiles] = useState(true);
    const [attemptToIdAgencies, setAttemptToIdAgencies] = useState(true);
    //[End] - Attempt To Id Tab

    //[Start] - Officer Safety
    const [officerSafetyPostsfilter, setOfficerSafetyPostsFilter] = useState(true);
    const [officerSafetyOfficers, setOfficerSafetyOfficers] = useState(true);
    const [officerSafetyFiles, setOfficerSafetyFiles] = useState(true);
    const [officerSafetyAgencies, setOfficerSafetyAgencies] = useState(true);
    //[End] - Officer Safety

    //[Start] - Related Tab
    const [relatedPostsfilter, setRelatedPostsFilter] = useState(true);
    const [relatedOfficers, setRelatedOfficers] = useState(true);
    const [relatedFiles, setRelatedFiles] = useState(true);
    const [relatedAgencies, setRelatedAgencies] = useState(true);
    //[End] - Related Tab

    // eslint-disable-next-line no-unused-vars
    const [roles, setRoles] = useState({});
    const [search, setsearch] = useState("");
    const [sharepostmodal, setSharePostModal] = useState(false);
    const [reportpostmodal, setReportPostModal] = useState(false);
    const [selectedImagePreview, setImagePreview] = useState(null);
    const [imageModal, setImageModal] = useState(false);
    const [loadingPostsShare, setLoadingShare] = useState(false);
    const [loadingPostsReport, setLoadingReport] = useState(false);
    const [folder, setFolder] = useState("All");
    const [folderItem, setFolderItem] = useState();
    const [usersharepost, setUserSharePost] = useState({
        id: "",
        name: "",
        incidentid: 0,
        incidentname: "",
        message: "",
        agencyId: 0
    });
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [selectedSharePost, setSelectedSharePost] = useState({});

    // eslint-disable-next-line no-unused-vars
    const [userRoles, setUserRoles] = useState(AuthService.getRoles());
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    //my post 

    const[dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("");
    const [draft, setDraft] = useState(true);
    const [published, setPublished] = useState(true);
    const [removed, setRemoved] = useState(true);
    const [optionReport, setOptionReport] = useState("");
    const [messageReport, setMessageReport] = useState("");
    

    useEffect(() => {
        if (userRoles.length > 0 && userRoles.includes("SuperAdmin")) {
        setIsSuperAdmin(true);
        }
    },[userRoles]);

    // if (props.listpost != null) {
    //     let listt = props.listpost.slice(0).reverse();
    // }

    
    const SelectOptionMenu = (item) => {
        SearchPost({ relate: item });
        setFolderItem(item);
        setFolder(item.folderName);
    }
    const toggle = () => {
        setDropDownOpen(!dropdownOpen);
    }

    //[Start] - All Tab
    const TogglePosts = (e) =>  setpostsfilter(!postsfilter);
    const Toggleofficers = (e) => setofficers(!officers);
    const Togglefiles = (e) =>  setfiles(!files);
    const ToggleAgencies = (e) => setAgencies(!agencies);
    //[End] - All Tab

     //[Start] - My Posts Tab
     const ToggleMyPosts = (e) => setMyPostsFilter(!myPostsfilter);
     const ToggleMyPostsOfficers = (e) => setMyPostsOfficers(!myPostsOfficers);
     const ToggleMyPostsFiles = (e) =>  setMyPostsFiles(!myPostsFiles);
     const ToggleMyPostsAgencies = (e) => setMyPostsAgencies(!myPostsAgencies);
    //[End] - My PostsTab

     //[Start] - My Department Posts Tab
     const ToggleMyDepartmentPosts = (e) => setMyDepartmentPostsFilter(!myDepartmentPostsFilter);
     const ToggleMyDepartmentPostsOfficers = (e) => setMyDepartmentPostsOfficers(!myDepartmentPostsOfficers);
     const ToggleMyDepartmentPostsFiles = (e) =>  setMyDepartmentPostsFiles(!myDepartmentPostsFiles);
     const ToggleMyDepartmentPostsAgencies = (e) => setMyDepartmentPostsAgencies(!myDepartmentPostsAgencies);
     //[End] - My Department Posts Tab

     //[Start] - Bolos Tab
     const ToggleBolosPosts = (e) =>  setBolosPostsFilter(!bolosPostsfilter);
     const ToggleBolosOfficers = (e) => setBolosOfficers(!bolosOfficers);
     const ToggleBolosFiles = (e) =>  setBolosFiles(!bolosFiles);
     const ToggleBolosAgencies = (e) => setBolosAgencies(!bolosAgencies);
    //[End] - Bolos Tab

    //[Start] - Attemp To Id Tab
    const ToggleattempToIdPosts = (e) => setAttemptToIdPostsFilter(!attemptToIdPostsfilter);
    const ToggleattempToIdOfficers = (e) => setAttemptToIdOfficers(!attemptToIdOfficers);
    const ToggleattempToIdFiles = (e) =>  setAttemptToIdFiles(!attemptToIdFiles);
    const ToggleattempToIdAgencies = (e) => setAttemptToIdAgencies(!attemptToIdAgencies);
    //[End] - Attemp To Id Tab

    //[Start] - Officer Safety Tab
    const ToggleOfficerSafetyPosts = (e) => setOfficerSafetyPostsFilter(!officerSafetyPostsfilter);
    const ToggleOfficerSafetyOfficers = (e) => setOfficerSafetyOfficers(!officerSafetyOfficers);
    const ToggleOfficerSafetyFiles = (e) =>  setOfficerSafetyFiles(!officerSafetyFiles);
    const ToggleOfficerSafetyAgencies = (e) => setOfficerSafetyAgencies(!officerSafetyAgencies);
    //[End] - Officer Safety Tab

     //[Start] - Related Tab
     const ToggleRelatedPosts = (e) => setRelatedPostsFilter(!relatedPostsfilter);
     const ToggleRelatedOfficers = (e) => setRelatedOfficers(!relatedOfficers);
     const ToggleRelatedFiles = (e) =>  setRelatedFiles(!relatedFiles);
     const ToggleRelatedAgencies = (e) => setRelatedAgencies(!relatedAgencies);
     //[End] - Related Tab

    const OpenSharePost = (item) => {
        setSelectedSharePost(item);    
        setSharePostModal(true);
        setUserSharePost({
            ...usersharepost,
            incidentid: item.id,
            incidentname: item.incidentNum,
            agencyId: item.agency.id
        });
    }

    const OpenReportPost = (item) => {
        setMessageReport("");
        setReportPostModal(true);
        setUserSharePost({
            ...usersharepost,
            incidentid: item.id,
            incidentname: item.incidentNum
           
        });
    }

    const OpenFolders = (e, item) => {
        e.preventDefault();
        props.OpenFolders(e, item);
    }

    const ToggleAnonymous = e => {
        setIsAnonymous(e.target.checked);
    }

    const ReportPost = (item) => {
        setLoadingReport(true);
        const formDataPublish = new FormData();

        formDataPublish.append("userid", "-");
        formDataPublish.append("incidentid", item.incidentid);
        formDataPublish.append("message", optionReport === "Other" ? messageReport : optionReport);
        formDataPublish.append("anonymous", isAnonymous);
        api.post("/incidents/report", formDataPublish, AuthService.getToken())
            .then(response => {

                if (response.data) {
                    NotificationManager.success('The post was reported , An administrator must validate this report', 'Success', 5000);
                    setLoadingReport(false);
                    setUserSharePost({
                        id: "",
                        name: "",
                        incidentid: 0,
                        incidentname: "",
                        message: ""
                    });
                    setIsAnonymous(false);
                    setReportPostModal(false);
                }


            });
    }

    const SharedPost = (item) => {
        setLoadingShare(true);
        const formDataPublish = new FormData();

        formDataPublish.append("userid", item.id);
        formDataPublish.append("incidentid", item.incidentid);
        formDataPublish.append("message", item.message);
        api.post("/incidents/share", formDataPublish, AuthService.getToken())
            .then(response => {

                if (response.data) {
                    NotificationManager.success('The post was shared', 'Success', 5000);
                    setLoadingShare(false);
                    setUserSharePost({
                        id: "",
                        name: "",
                        incidentid: 0,
                        incidentname: "",
                        message: ""
                    });
                    setSharePostModal(false);
                }


            });
    }

    const SelectUser = useCallback((item) => {
        let img = item.urlPic;
        if (selectedSharePost !== undefined && (selectedSharePost.filesIncidents != null && selectedSharePost.filesIncidents !== undefined && selectedSharePost.filesIncidents.length >0)) {
            img = selectedSharePost.filesIncidents[0].file.path;
        }

        history.push({
            pathname: `/messages`,
            search: `?userId=${item.id}&name=${item.name}&type=${selectedSharePost !== undefined ? selectedSharePost.type : '-'}&typechat=${'incident'}&incident=${selectedSharePost!== undefined ? selectedSharePost.id : ""}&address=${selectedSharePost !== undefined ? selectedSharePost.address : ""}&mode=share`,
            state: {
                urlPic: img,
                userId: item.id,
                name: item.name,
                type: (selectedSharePost !== undefined ? selectedSharePost.type : '-'),
                typechat: 'incident',
                incident: selectedSharePost!== undefined ? selectedSharePost.id : "",
                incidentNum: selectedSharePost !== undefined ? selectedSharePost.incidentNum : "",
                address: selectedSharePost !== undefined ? selectedSharePost.address : "",
                isSharePost: true,
                userFullName: item.name.trim(),
                userAddress: item.address,
                email: item.email,
                phone: item.phone,
            }

        });
        
        // setUserSharePost({
        //     ...usersharepost,
        //     id: item.id,
        //     name: item.name,
        //     img:item.urlPic
        // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedSharePost])

    const [isExecuteSearchPost, setIsExecuteSearchPost] = useState(false);
    const [isExecuteByCurrentPage, setIsExecuteByCurrentPage] = useState(false);
    const [isTextSearchExecuted, setIsTextSearchExecuted] = useState(false);
    const [isParentPagination, setIsParentPagination] = useState(true);

    const OnChangeSearch = (e) => {
        setsearch(e.target.value);
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);
    }

    //Set some delay when typing
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsExecuteSearchPost(false);
            setIsExecuteSearchPost(true);
        }, 1500)
    
        return () => clearTimeout(delayDebounceFn)
      }, [search])

      useEffect(() => {
        if(isExecuteSearchPost === true && isTextSearchExecuted === true) {
            setIsParentPagination(false);
            SearchPost();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteSearchPost,isTextSearchExecuted])

    useEffect(() => {
        if(props.IsAfterDelete === true && isTextSearchExecuted) {
            SearchPost();
            if(search !== "") setsearch("");
        }

    },[props.IsAfterDelete,search,isTextSearchExecuted]);


    const [currentPage, setCurrentPage] = useState(1);

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    const SearchPost = useCallback((filter) => {
        setLoadingPosts(true);

        var dateE = "";
        var dateS = "";

        if (filter !== undefined && filter.end !== undefined) {
            dateE = moment(filter.end).format("YYYY/MM/DD");
            dateS = moment(filter.start).format("YYYY/MM/DD");
        } else if (dateStart !== undefined && dateStart !== "") {
            dateE = moment(dateEnd).format("YYYY/MM/DD");
            dateS = moment(dateStart).format("YYYY/MM/DD");
        }

        //var get = "/incidents/listsearch?start=" + dateS + "&end=" + dateE + "&search=" + search + "&activefile=" + files + "&activeincidents=" + postsfilter + "&activeagency=" + agencies + "&activeofficer=" + officers + "&type=" + props.Key;
        let get = "/incidents/listsearch/get?page="+ currentPage  +"&take=7&start=" + dateS + "&end=" + dateE + "&search=" + search + "&activefile=" + files + "&activeincidents=" + postsfilter + "&activeagency=" + agencies + "&activeofficer=" + officers + "&type=" + props.Key;

        switch (props.Key) {
        case 'Bolos':
            get = "/incidents/listsearch/get?page="+ currentPage  +"&take=7&start=" + dateS + "&end=" + dateE + "&search=" + search + "&activefile=" + bolosFiles + "&activeincidents=" + bolosPostsfilter + "&activeagency=" + bolosAgencies + "&activeofficer=" + bolosOfficers + "&type=" + props.Key;
            break;
        case 'AttempttoId':
            get = "/incidents/listsearch/get?page="+ currentPage  +"&take=7&start=" + dateS + "&end=" + dateE + "&search=" + search + "&activefile=" + attemptToIdFiles + "&activeincidents=" + attemptToIdPostsfilter + "&activeagency=" + attemptToIdAgencies + "&activeofficer=" + attemptToIdOfficers + "&type=" + props.Key;
            break;
        case 'OfficerSafety':
            get = "/incidents/listsearch/get?page="+ currentPage  +"&take=7&start=" + dateS + "&end=" + dateE + "&search=" + search + "&activefile=" + officerSafetyFiles + "&activeincidents=" + officerSafetyPostsfilter + "&activeagency=" + officerSafetyAgencies + "&activeofficer=" + officerSafetyOfficers + "&type=" + props.Key;
            break;    
        case 'MyPost':
            //get = "/incidents/listsearch/get?page="+ currentPage  +"&take=7&start=" + dateS + "&end=" + dateE + "&search=" + search + "&activefile=" + myPostsFiles + "&activeincidents=" + myPostsfilter + "&activeagency=" + myPostsAgencies + "&activeofficer=" + myPostsOfficers + "&type=" + props.Key;
            get = "/incidents/mypost/get?page="+currentPage+"&take=7&dstart=" + dateS + "&dend=" + dateE + "&search=" + search + "&draft=" + draft + "&published=" + published + "&removed=" + removed;
            break;   
        case 'related':
           // get = "/incidents/listsearch/get?page="+ currentPage  +"&take=7&start=" + dateS + "&end=" + dateE + "&search=" + search + "&activefile=" + relatedFiles + "&activeincidents=" + relatedPostsfilter + "&activeagency=" + relatedAgencies + "&activeofficer=" + relatedOfficers + "&type=" + props.Key;
            get = "/releatedcrimes/IncidentsFolder/get?page="+currentPage+"&take=7&folderid=" +
                (filter !== undefined && filter.relate !== undefined ? filter.relate.id : folderItem !== undefined ? folderItem.id :0)
                + "&dstart=" + dateS + " & dend=" + dateE + " & search=" + search + " & draft=" + draft + " & published=" + published + " & removed=" + removed;
            break;  
        case 'myDepartment':
            //get = "/incidents/listsearch/get?page="+ currentPage  +"&take=7&start=" + dateS + "&end=" + dateE + "&search=" + search + "&activefile=" + myDepartmentPostsFiles + "&activeincidents=" + myDepartmentPostsFilter + "&activeagency=" + myDepartmentPostsAgencies + "&activeofficer=" + myDepartmentPostsOfficers + "&type=" + props.Key;
            get = "/incidents/incidentsdepartment/get?page="+currentPage+"&take=7&dstart=" + dateS + "&dend=" + dateE + "&search=" + search + "&draft=" + draft + "&published=" + published + "&removed=" + removed;
            break;  
        default:
            //console.log(props.Key);
        }
        
        api.get(get, AuthService.getTokenJson())
            .then((response) => {
                let users = [];
                let incidentsx = [];
                let agenciesx = [];
                let filesx = [];

                var _data;
                _data = response.data.data;

                const { totalPages, data, totalData } =  response.data;
                setPageData({
                    isLoading: false,
                    rowData: data,
                    totalPages,
                    totalData,
                });
                

                setSearched(true);
                _data.forEach((element) => {
                    incidentsx.push(element);

                    var countfiltered = users.filter(function (z) {
                        return z.id === element.user.id;
                    }).length;
                    if (countfiltered === 0)
                        users.push(element.user);

                    var countfilteredagencie = agenciesx.filter(function (z) {
                        return element.agency !== null && element.agency !== undefined && z.id === element.agency.id;
                    }).length;
                    if (countfilteredagencie === 0)
                        agenciesx.push(element.agency);

                    element.filesIncidents.forEach((file) => {
                        let fl = {
                            file: file,
                            incident: element
                        };
                        filesx.push(fl);
                    });
                });
                setListOfficer(users);
                setListPost(incidentsx);
                setListFiles(filesx);
                setListAgencies(agenciesx);
                setLoadingPosts(false);
                setIsExecuteByCurrentPage(false);
            }).finally(() => {
                if(isParentPagination === true) setIsParentPagination(false);
            });

       

    },[currentPage,agencies,dateEnd,dateStart,draft,files,folderItem,officers,postsfilter,props.Key,published,removed,search,setLoadingPosts,isParentPagination
    ,myPostsfilter,myPostsOfficers,myPostsFiles,myPostsAgencies
    ,myDepartmentPostsFilter,myDepartmentPostsOfficers,myDepartmentPostsFiles,myDepartmentPostsAgencies
    ,bolosPostsfilter,bolosOfficers,bolosFiles,bolosAgencies
    ,attemptToIdPostsfilter,attemptToIdOfficers,attemptToIdFiles,attemptToIdAgencies
    ,officerSafetyPostsfilter,officerSafetyOfficers,officerSafetyFiles,officerSafetyAgencies
    ,relatedPostsfilter,relatedOfficers,relatedFiles,relatedAgencies
    ]);

    const onCurrentPageChange = useCallback((pageNum) => {
        if(isExecuteByCurrentPage === false && loadingPosts === false) {
            setCurrentPage(pageNum);
            setIsExecuteByCurrentPage(true);
        }
    
    },[isExecuteByCurrentPage,loadingPosts]);

    useEffect(() => {
        if(isExecuteByCurrentPage === true) {
            SearchPost();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage,isExecuteByCurrentPage]);

    const ChangeMessage = (e) => {
        setUserSharePost({
            ...usersharepost,
            message: e.target.value
        });
    }
    const toggleModal = () => {
        
        setSharePostModal(false);
        setUserSharePost({
            id: "",
            name: "",
            incidentid: 0,
            incidentname: "",
            message: "",
            img:""
        });
    }
    const toggleModalReport = () => {
        setReportPostModal(false);
        
    }
    const toggleModalImage = () => {

        setImageModal(false);

    }
    const [modalPublish, setModalPublish] = React.useState({
        show: false,
        id: "",
        name: ""
    });
    const togglePublish = (incident) => {
        setModalPublish({
            ...modalPublish,
            show: !modalPublish.show,
            id: incident.id,
            name: incident.incidentNum
        });

    }
    const PreviewImage = (imgprev) => {

        if (imgprev.file.type.includes("image/")) {
            setImageModal(true);
            setImagePreview(imgprev);
        }
        else  {
            window.open(imgprev.file.path);
        }
    }
    const ChangeMessageReport = (e) => {
        setMessageReport(e.target.value);
    }
    // eslint-disable-next-line no-unused-vars
    const UnPublishPost = (id) => {

        const formDataPublish = new FormData();

        formDataPublish.append("id", id);

        api.post("/incidents/unpublish", formDataPublish, AuthService.getToken())
            .then(response => {

                if (response.data) {
                    reloadData();
                    NotificationManager.success('The post was un-officers', 'Success', 5000);
                    togglePublish(false);

                }


            });
    }
    useEffect(() => {
        if (isSearched) {
            SearchPost();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postsfilter, files, officers, agencies,relatedPostsfilter,relatedOfficers,relatedFiles,relatedAgencies
    ,myPostsfilter,myPostsOfficers,myPostsFiles,myPostsAgencies
    ,myDepartmentPostsFilter,myDepartmentPostsOfficers,myDepartmentPostsFiles,myDepartmentPostsAgencies
    ,bolosPostsfilter,bolosOfficers,bolosFiles,bolosAgencies
    ,attemptToIdPostsfilter,attemptToIdOfficers,attemptToIdFiles,attemptToIdAgencies
    ,officerSafetyPostsfilter,officerSafetyOfficers,officerSafetyFiles,officerSafetyAgencies
    ,relatedPostsfilter,relatedOfficers,relatedFiles,relatedAgencies
    ]);

    useEffect(() => {
        let userauth = AuthService.getCurrentUser();
        setUserLogged(userauth);

        let rolesx = userauth != null && userauth.roles != null && !userauth.roles !== "" ? userauth.roles.split(',') : [];
        setRoles(rolesx);

    }, [setUserLogged, setRoles]);

    const SearchFilterDate = useCallback((e) => {
        setDateStart(e.start);
        setDateEnd(e.end);
        // const dateE = moment(e.end).format("YYYY/MM/DD");
        // const dateS = moment(e.start).format("YYYY/MM/DD");
        if(isParentPagination === true) setIsParentPagination(false);
        SearchPost(e);

    },[isParentPagination,SearchPost])

    const ChangeOption = (e) => {
        setOptionReport(e.target.value);
    }
    const ToogleDraft = (e) => {
        setDraft(!draft);
        props.LoadIncidents(dateStart, dateEnd, search, !draft, published, removed);
    }
    const TooglePublished = (e) => {
        setPublished(!published);
        props.LoadIncidents(dateStart, dateEnd, search, draft, !published, removed);
    }
    const ToogleRemoved = (e) => {
        setRemoved(!removed);
        props.LoadIncidents(dateStart, dateEnd, search, draft, published, !removed);
    }

    const PrimaryFontFamily = "Work Sans";
    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
          setIsReadMore(!isReadMore);
        };
        return (
            <p className="text" style={{fontFamily: PrimaryFontFamily, fontWeight: 500, fontSize: "16px"}}>
                {isReadMore ? text.slice(0, 200) : text} {"  "}
                { text.length > 200 ? 
                    ( <span onClick={toggleReadMore} className="read-or-hide" style={{color: "#002542", cursor: "pointer", fontSize: "16px", fontWeight: 400, textDecoration: "underline",fontFamily: PrimaryFontFamily}}>
                        {isReadMore ? "Read More..." : "Show Less..."}
                    </span>) 
                    : (<></>)
                }
            </p>
        );
      };

    useEffect(() => {
        if(props.setIsParentPagination !== undefined) {
            props.setIsParentPagination(isParentPagination);
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isParentPagination]);

    return (
        <>

            <div className="header-content">
                
                <div className="row">
                    {!infopost &&
                        <>
                        <div className="col-3">
                            <div className="input-group mb-12">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                        <FontAwesomeIcon className="svgIconGrey" icon={faMagnifyingGlass} />
                                    </span>
                                </div>
                                <input type="text" className="form-control" placeholder="Keywords search..." onChange={OnChangeSearch} value={search} />
                            </div>
                        </div>
                      
                    <div className="col-4">
                        <CustomPicker SearchFilterDate={SearchFilterDate} />
                    </div>
                        </>
                    }
                    
                        
                    <div className="col-2">
                        {props.keytype !== undefined && props.keytype === "related" &&
                            <div className="btn-group" role="group" aria-label="Basic mixed styles example">

                                <button type="button" className="btn btn-white" onClick={() => setViewSelect("grid")}>
                                    {viewselect === "grid" &&
                                        <img src={icongridact} className="iconbtnswitch" alt="grid" />}
                                    {viewselect === "tree" &&
                                        <img src={icongrid} className="iconbtnswitch" alt="tree" />
                                    }

                                </button>
                                <button type="button" className="btn btn-white" onClick={() => setViewSelect("tree")}>
                                    {viewselect === "grid" &&
                                        <img src={icontree} className="iconbtnswitch" alt="grid"  />}
                                    {viewselect === "tree" &&
                                        <img src={icontreeact} className="iconbtnswitch"  alt="tree"  />
                                    }
                                </button>
                            </div>
                        }
                    </div>
                    
                    {props.keytype !== undefined && props.keytype === "related" &&
                        <div className="col-3">

                        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdownfolder" >
                            <DropdownToggle caret className="tooglefolder">
                                <img src={folderpng} className="foldersaved" alt="saved" />    {folder}
                                </DropdownToggle>





                                <DropdownMenu>
                                    <DropdownItem onClick={() => SelectOptionMenu({ id: 0, folderName: "All" })}> <img src={folderpng} className="foldersaved" alt="saved" /> All</DropdownItem>
                                    {props.listfolders.map((item) => (

                                        <DropdownItem key={item.id} onClick={() => SelectOptionMenu(item)}> <img src={folderpng} className="foldersaved" alt="saved" /> {item.folderName}</DropdownItem>
                                    ))}
                                </DropdownMenu>


                            </Dropdown>
                        </div>
                    }
                </div>
                {!loadingPosts && isSearched && props.listpost.length > 0 &&
                    <>
                        <br />
                        <div className="row">
                            <label className="label-control">Filter by:</label>

                            {
                             props.Key === "All" && 
                             (
                                <CardPostFilterBy 
                                    Key={props.Key} 
                                    Posts={postsfilter} TogglePosts={TogglePosts}
                                    Officers={officers} ToggleOfficers={Toggleofficers} 
                                    Files={files} ToggleFiles={Togglefiles}
                                    Agencies={agencies} ToggleAgencies={ToggleAgencies}
                                />
                             )
                             }
                            
                            {
                             props.Key === "MyPost" && 
                             (
                                <CardPostFilterBy 
                                    Key={props.Key} 
                                    Posts={myPostsfilter} TogglePosts={ToggleMyPosts}
                                    Officers={myPostsOfficers} ToggleOfficers={ToggleMyPostsOfficers} 
                                    Files={myPostsFiles} ToggleFiles={ToggleMyPostsFiles}
                                    Agencies={myPostsAgencies} ToggleAgencies={ToggleMyPostsAgencies}
                                />
                             )
                             }

                            {
                             props.Key === "myDepartment" && 
                             (
                                <CardPostFilterBy 
                                    Key={props.Key} 
                                    Posts={myDepartmentPostsFilter} TogglePosts={ToggleMyDepartmentPosts}
                                    Officers={myDepartmentPostsOfficers} ToggleOfficers={ToggleMyDepartmentPostsOfficers} 
                                    Files={myDepartmentPostsFiles} ToggleFiles={ToggleMyDepartmentPostsFiles}
                                    Agencies={myDepartmentPostsAgencies} ToggleAgencies={ToggleMyDepartmentPostsAgencies}
                                />
                             )
                             }

                            {
                             props.Key === "Bolos" && 
                             (
                                <CardPostFilterBy 
                                    Key={props.Key} 
                                    Posts={bolosPostsfilter} TogglePosts={ToggleBolosPosts}
                                    Officers={bolosOfficers} ToggleOfficers={ToggleBolosOfficers} 
                                    Files={bolosFiles} ToggleFiles={ToggleBolosFiles}
                                    Agencies={bolosAgencies} ToggleAgencies={ToggleBolosAgencies}
                                />
                             )
                             }

                            {
                             props.Key === "AttempttoId" && 
                             (
                                <CardPostFilterBy 
                                    Key={props.Key} 
                                    Posts={attemptToIdPostsfilter} TogglePosts={ToggleattempToIdPosts}
                                    Officers={attemptToIdOfficers} ToggleOfficers={ToggleattempToIdOfficers} 
                                    Files={attemptToIdFiles} ToggleFiles={ToggleattempToIdFiles}
                                    Agencies={attemptToIdAgencies} ToggleAgencies={ToggleattempToIdAgencies}
                                />
                             )
                             }

                            {
                             props.Key === "OfficerSafety" && 
                             (
                                <CardPostFilterBy 
                                    Key={props.Key} 
                                    Posts={officerSafetyPostsfilter} TogglePosts={ToggleOfficerSafetyPosts}
                                    Officers={officerSafetyOfficers} ToggleOfficers={ToggleOfficerSafetyOfficers} 
                                    Files={officerSafetyFiles} ToggleFiles={ToggleOfficerSafetyFiles}
                                    Agencies={officerSafetyAgencies} ToggleAgencies={ToggleOfficerSafetyAgencies}
                                />
                             )
                             }

                            {
                             props.Key === "related" && 
                             (
                                <CardPostFilterBy 
                                    Key={props.Key} 
                                    Posts={relatedPostsfilter} TogglePosts={ToggleRelatedPosts}
                                    Officers={relatedOfficers} ToggleOfficers={ToggleRelatedOfficers} 
                                    Files={relatedFiles} ToggleFiles={ToggleRelatedFiles}
                                    Agencies={relatedAgencies} ToggleAgencies={ToggleRelatedAgencies}
                                />
                             )
                             }
                            

                        </div>
                    </>
                }

                {
                    props.myPosts && props.myPosts === true && !isSearched &&
                    <>
                    <br/>
                        <div className="row">
                            <div className="col-1" style={{marginRight: "10px"}}>
                                <input type="checkbox" className="btn-check" id="btn-check-1-outlined-draft" onChange={ToogleDraft} checked={draft} />
                                <label className={`btn btn-primary ${draft ? "" : "inactive"} label-filter-width` } htmlFor="btn-check-1-outlined-draft">DRAFT</label><br />
                            </div>
                            <div className="col-1" style={{marginRight: "10px"}}>
                                <input type="checkbox" className="btn-check" id="btn-check-2-outlined-published" onChange={TooglePublished} checked={published} />
                                <label className={`btn btn-primary ${published ? "" : "inactive"} label-filter-width`} htmlFor="btn-check-2-outlined-published">PUBLISHED</label><br />

                            </div>
                            <div className="col-1" style={{marginRight: "10px"}}>
                                <input type="checkbox" className="btn-check" id="btn-check-3-outlined-removed" onChange={ToogleRemoved} checked={removed} />
                                <label className={`btn btn-primary ${removed ? "" : "inactive"} label-filter-width`} htmlFor="btn-check-3-outlined-removed">REMOVED</label><br />
                            </div>
                        </div>
                        <br />
                    </>
                }

            </div>
            <br />
            
            {
                loadingPosts &&
                <div className="items">
                    <LoaderPost />
                </div>
            }

            {!loadingPosts && !isSearched &&
            <div className="scroll-container" ref={viewPortRef}>
                {props.listpost.length === 0 &&
                    <AnyPost isSuperAdmin={isSuperAdmin} />
                }
                {
                    props.listpost.length > 0 ? (
                        <ViewportList ref={viewPortListRef} viewportRef={viewPortRef} items={props.listpost} itemMinSize={40} margin={8}>
                            {(item) => (
                                   <div key={item.id} className="row">
                                   {viewselect === "grid" &&
                                       <div className="col-12">
                                           <div key={item.id} className="card cardpost">
                                               <div className="row">
                                                        <div className="col-md-3">
                                                            {item.filesIncidents && item.filesIncidents.length > 0 ? (
                                                                <>
                                                                    <div className="container-img">
                                                                        {item.filesIncidents[0].file.type === "application/pdf" && 
                                                                            <img onClick={() => PreviewImage(item.filesIncidents[0])} key={item.filesIncidents[0].id} className="card-img-top" src={iconpdf} aria-hidden alt="Card image cap" />
                                                                        }
                                                                        {item.filesIncidents[0].file.type.includes("image/") &&
                                                                            <img onClick={() => PreviewImage(item.filesIncidents[0])} key={item.filesIncidents[0].id} className="card-img-top" src={item.filesIncidents[0].file.path} aria-hidden alt="Card image cap" />
                                                                        }
                                                                        {item.filesIncidents[0].file.type.includes("officedocument.word") &&
                                                                            <img onClick={() => PreviewImage(item.filesIncidents[0])} key={item.filesIncidents[0].id} className="card-img-top" src={WordIcon} aria-hidden alt="Card image cap" />
                                                                        }
                                                                        {item.filesIncidents[0].file.type.includes("officedocument.spreadsheetml") &&
                                                                            <img onClick={() => PreviewImage(item.filesIncidents[0])} key={item.filesIncidents[0].id} className="card-img-top" src={iconexcel} aria-hidden alt="Card image cap" />
                                                                        }
                                                                        {item.filesIncidents[0].file.type.includes("officedocument.presentationm") &&
                                                                            <img onClick={() => PreviewImage(item.filesIncidents[0])} key={item.filesIncidents[0].id} className="card-img-top" src={iconpower} aria-hidden alt="Card image cap" />
                                                                        }
                                                                        {item.filesIncidents[0].file.type.includes("text/plain") &&
                                                                            <img onClick={() => PreviewImage(item.filesIncidents[0])} key={item.filesIncidents[0].id} className="card-img-top" src={icontxt} aria-hidden alt="Card image cap" />
                                                                        }
                                                                        
                                                                        {(item.filesIncidents[0].file.type.includes("application/x-zip-compressed") || item.filesIncidents[0].file.type.includes("application/octet-stream")) &&
                                                                            <img onClick={() => PreviewImage(item.filesIncidents[0])} key={item.filesIncidents[0].id} className="card-img-top" src={iconrarzip} aria-hidden alt="Card image cap" />
                                                                        }
                                                                    </div>
                                                                </>
                                                                ) 
                                                                : 
                                                                (
                                                                    <>
                                                                        <div className="container-img">
                                                                            <img  src={iconnopreview} aria-hidden alt="Card image cap" />
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                   </div>
                                                   <div className="col-md-9 marginPost">
                                                       <div className="row">
                                                           <div className="col-11">
                                                               <label className="textBlue" >{item.agencyName}</label>
                                                       </div>
                                                       {!infopost&&
                                                           <div className="col-1">
                                                               <button id={`${props.Key}${item.id}`} className="btn btn-default btnMore"><img src={dotsMore} alt="more" /></button>
                                                               <UncontrolledPopover
                                                                   trigger="legacy"
                                                                   placement="bottom"
                                                                   target={`${props.Key}${item.id}`}
                                                               >
       
                                                                   <PopoverBody>
                                                                   <OptionsPost  SetNotification={props.SetNotfication} DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents} OpenFolders={OpenFolders} data={item} OpenSharePost={(event) => OpenSharePost(item)} OpenReportPost={(event) => OpenReportPost(item)} IsSuperAdmin={isSuperAdmin} AfterRemoveRelateCrime={props.AfterRemoveRelateCrime}></OptionsPost>
                                                                   </PopoverBody>
                                                               </UncontrolledPopover>
                                                           </div>
                                                       }
                                                       </div>
                                                       <div className="row">
                                                           <label className="incidenTitle">Incident: {item.incidentNum}</label>
                                                       </div>
                                                       <div className="row">
                                                           <div className="col-12">
                                                               <FontAwesomeIcon className="svgIconGrey" icon={faClock} />
                                                               &nbsp;
                                                               <span className="cardSpan">
                                                                    {item.date2}
                                                               </span>
                                                               &nbsp;&nbsp;&nbsp;
                                                               <FontAwesomeIcon className="svgIconGrey" icon={faLocationDot} />
                                                               &nbsp;
                                                               <span className="cardSpan">
                                                                   {item.address}
                                                               </span>
                                                               &nbsp;&nbsp;&nbsp;
                                                               <FontAwesomeIcon className="svgIconGrey" icon={faFolderOpen} />
                                                               &nbsp;
                                                               <span className="cardSpan">
                                                                   {item.type}
                                                               </span>
                                                           </div>
       
                                                       </div>
                                                       <div className="row">
                                                           <div className="col-12">
                                                               <ReadMore>
                                                                   {item.description}
                                                               </ReadMore>
                                                           </div>
                                                           
                                                       </div>
       
                                                       <div className="row">
                                                           <div className="col-12">
                                                               {
                                                                   item.filesIncidents && item.filesIncidents.length > 1 && item.filesIncidents.slice(1).map((file) => {
                                                                       return <>
                                                                           <div className="thumbNailImageIncident">
                                                                               {file.file.type.includes("image/") &&
                                                                                   <img key={file.id}
                                                                                       className="thumbimg" onClick={() => PreviewImage(file)} src={file.file.path} aria-hidden alt="Card image cap" />
                                                                               }
                                                                               {file.file.type === "application/pdf" &&
                                                                                   <img onClick={() => PreviewImage(file)} key={file.id} className="thumbimg" src={iconpdf} aria-hidden alt="Card image cap" />
                                                                               }
                                                                               {
                                                                                    /**[START] COMMENTED TEMP**/
                                                                               }
                                                                               {/* {file.file.type.includes("officedocument.word") &&
                                                                                   <img onClick={() => PreviewImage(file)} key={file.id} className="thumbimg" src={iconword} aria-hidden alt="Card image cap" />
                                                                               } */}
                                                                               {
                                                                                    /**[END] COMMENTED TEMP**/
                                                                               }
                                                                               {file.file.type.includes("officedocument.spreadsheetml") &&
                                                                                   <img onClick={() => PreviewImage(file)} key={file.id} className="thumbimg" src={iconexcel} aria-hidden alt="Card image cap" />
                                                                               }
                                                                               {file.file.type.includes("officedocument.presentationm") &&
                                                                                   <img onClick={() => PreviewImage(file)} key={file.id} className="thumbimg" src={iconpower} aria-hidden alt="Card image cap" />
                                                                               }
                                                                               {file.file.type.includes("text/plain") &&
                                                                                   <img onClick={() => PreviewImage(file)} key={file.id} className="thumbimg" src={icontxt} aria-hidden alt="Card image cap" />
                                                                               }
                                                                               {(file.file.type.includes("application/x-zip-compressed") || file.file.type.includes("application/octet-stream")) &&
                                                                                   <img onClick={() => PreviewImage(file)} key={file.id} className="thumbimg" src={iconrarzip} aria-hidden alt="Card image cap" />
                                                                               }
       
                                                                           </div>
                                                                       </>
                                                                           
                                                                   })
                                                               }
                                                           </div>
                                                       </div>
       
                                                       <br />
                                                       <br />
                                                       <div className="row">
                                                           <div className="col-11">
                                                               {
                                                                   item.myPost && item.myPost === true &&
                                                                   <>
                                                                       <label className="lblProfileNoCursor">My Posts</label>
                                                                   </>
                                                               }
       
                                                               {
                                                                   item.myPost === false &&
                                                                   <>
                                                                       <label className="cardSpan">
                                                                           {item.division ? item.division + ":" : ""}&nbsp;
                                                                       </label>

                                                                    
       
                                                                       <label className="lblProfile" id={`PopoverLegacy${item.id}`} >
                                                                          {item.userName} &nbsp; {StatusBadge(item.user.deleted ? 3 : item.user.appStatus === 1 ? 2 : 1 )} &nbsp; <FontAwesomeIcon className="svgIconGrey" icon={faAngleDown} />
                                                                       </label>

                                                          
       
                                                                       <UncontrolledPopover target={`PopoverLegacy${item.id}`}
                                                                           trigger="legacy"
                                                                           placement="top-start"
                                                                       >
       
                                                                           <PopoverBody>
                                                                           <ProfileCard Incident={item} openMessage={props.openMessage} id={item.userId} ></ProfileCard>
                                                                           </PopoverBody>
       
                                                                       </UncontrolledPopover>
                                                                   </>
                                                               }
       
                                                           </div>
                                                        
       
                                                       </div>
                                                   </div>
       
                                               </div>
       
       
       
                                           </div>
                                       </div>
                                   }
                                 
                               </div>
                            )}
                        </ViewportList>

                    ) : (<></>)
                }
                
                {props.listpost.slice(0).reverse().map((item) => (
                    <div key={item.id} className="row">
                    { viewselect === "tree" &&
                    <>
                        <div className="row">
                            <div className="col-4">
                            </div>
                            <div className="col-5">
                                <div className="row">
                                    <div className="col-1 coldot">
                                            <span className="dot"></span>
                                    </div>
                                    <div className="col-11 rowtime">
                                        <div className="row ">
                                            <div className="col-9">
                                                <label>{item.date}</label>

                                            </div>
                                            <div className="col-3">
                                                <label>{item.hours}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 linetime">
                                            <div className="row rowincident">
                                                <label>Incident : {item.incidentNum}</label>
                                            </div>
                                            <div className="row rowinfo">
                                                <div className="col-12">
                                                    <label><FontAwesomeIcon className="svgIconGrey" icon={faFolderOpen} />
                                                        &nbsp;
                                                        <span className="cardSpan">
                                                            {item.type}
                                                        </span></label>
                                                </div>
                                                <div className="col-12">
                                                    <FontAwesomeIcon className="svgIconGrey" icon={faLocationDot} />
                                                    &nbsp;
                                                    <span className="cardSpan">
                                                        {item.address}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            </div>

                    </>
                        }
                    </div>
                ))}

            </div>
            }

            {!loadingPosts && isSearched && listpost.length > 0 &&


                <div className="postitem">
                        {
                            //[Start] - Officer
                        }
                        {props.Key === "All" && officers && (<CardPostOfficersCard listofficer={listofficer} OpenMessage={props.openMessage} />)}
                        {props.Key === "MyPost" && myPostsOfficers && (<CardPostOfficersCard listofficer={listofficer} OpenMessage={props.openMessage} />)}
                        {props.Key === "myDepartment" && myDepartmentPostsOfficers && (<CardPostOfficersCard listofficer={listofficer} OpenMessage={props.openMessage} />)}
                        {props.Key === "Bolos" && bolosOfficers && (<CardPostOfficersCard listofficer={listofficer} OpenMessage={props.openMessage} />)}
                        {props.Key === "AttempttoId" && attemptToIdOfficers && (<CardPostOfficersCard listofficer={listofficer} OpenMessage={props.openMessage} />)}
                        {props.Key === "OfficerSafety" && officerSafetyOfficers && (<CardPostOfficersCard listofficer={listofficer} OpenMessage={props.openMessage} />)}
                        {props.Key === "related" && relatedOfficers && (<CardPostOfficersCard listofficer={listofficer} OpenMessage={props.openMessage} />)}
                        {
                            //[End] - Officer
                        }

                        {
                            //[Start] - Posts
                        }
                         {props.Key === "All" && postsfilter && (<CardPostListCard SetNotification={props.SetNotfication} DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents}  OpenFolders={OpenFolders} OpenSharePost={OpenSharePost} OpenReportPost={OpenReportPost} IsSuperAdmin={isSuperAdmin} listpost={listpost} />)}
                         {props.Key === "MyPost" && myPostsfilter && (<CardPostListCard SetNotification={props.SetNotfication} DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents}  OpenFolders={OpenFolders} OpenSharePost={OpenSharePost} OpenReportPost={OpenReportPost} IsSuperAdmin={isSuperAdmin} listpost={listpost} />)}
                         {props.Key === "myDepartment" && myDepartmentPostsFilter && (<CardPostListCard SetNotification={props.SetNotfication} DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents}  OpenFolders={OpenFolders} OpenSharePost={OpenSharePost} OpenReportPost={OpenReportPost} IsSuperAdmin={isSuperAdmin} listpost={listpost} />)}
                         {props.Key === "Bolos" && bolosPostsfilter && (<CardPostListCard SetNotification={props.SetNotfication} DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents}  OpenFolders={OpenFolders} OpenSharePost={OpenSharePost} OpenReportPost={OpenReportPost} IsSuperAdmin={isSuperAdmin} listpost={listpost} />)}
                         {props.Key === "AttempttoId" && attemptToIdPostsfilter && (<CardPostListCard SetNotification={props.SetNotfication} DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents}  OpenFolders={OpenFolders} OpenSharePost={OpenSharePost} OpenReportPost={OpenReportPost} IsSuperAdmin={isSuperAdmin} listpost={listpost} />)}
                         {props.Key === "OfficerSafety" && officerSafetyPostsfilter && (<CardPostListCard SetNotification={props.SetNotfication} DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents}  OpenFolders={OpenFolders} OpenSharePost={OpenSharePost} OpenReportPost={OpenReportPost} IsSuperAdmin={isSuperAdmin} listpost={listpost} />)}
                         {props.Key === "related" && relatedPostsfilter && (<CardPostListCard SetNotification={props.SetNotfication} DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents}  OpenFolders={OpenFolders} OpenSharePost={OpenSharePost} OpenReportPost={OpenReportPost} IsSuperAdmin={isSuperAdmin} listpost={listpost} />)}
                        {
                            //[End] - Posts
                        }

                        {
                            //[Start] - Files
                        }
                         {props.Key === "All" && files && (<CardPostFileList listfiles={listfiles} />)}
                         {props.Key === "MyPost" && myPostsFiles && (<CardPostFileList listfiles={listfiles} />)}
                         {props.Key === "myDepartment" && myDepartmentPostsFiles && (<CardPostFileList listfiles={listfiles} />)}
                         {props.Key === "Bolos" && bolosFiles && (<CardPostFileList listfiles={listfiles} />)}
                         {props.Key === "AttempttoId" && attemptToIdFiles && (<CardPostFileList listfiles={listfiles} />)}
                         {props.Key === "OfficerSafety" && officerSafetyFiles && (<CardPostFileList listfiles={listfiles} />)}
                         {props.Key === "related" && relatedFiles && (<CardPostFileList listfiles={listfiles} />)}
                        {
                            //[End] - Files
                        }

                        {
                            //[Start] - Agencies List
                        }
                         {props.Key === "All" && agencies && (<CardPostAgenciesList listagencies={listagencies} />)}
                         {props.Key === "MyPost" && myPostsAgencies && (<CardPostAgenciesList listagencies={listagencies} />)}
                         {props.Key === "myDepartment" && myDepartmentPostsAgencies && (<CardPostAgenciesList listagencies={listagencies} />)}
                         {props.Key === "Bolos" && bolosAgencies && (<CardPostAgenciesList listagencies={listagencies} />)}
                         {props.Key === "AttempttoId" && attemptToIdAgencies && (<CardPostAgenciesList listagencies={listagencies} />)}
                         {props.Key === "OfficerSafety" && officerSafetyAgencies && (<CardPostAgenciesList listagencies={listagencies} />)}
                         {props.Key === "related" && relatedAgencies && (<CardPostAgenciesList listagencies={listagencies} />)}
                        {
                            //[End] - Agencies List
                        }

                </div>
            }
            {!loadingPosts && isSearched && listpost.length === 0 &&
                <AnyPost isSuperAdmin={isSuperAdmin} />

            }

            {isParentPagination === false && pageData.totalData > 0 ? 
                (<div>
                    <Pagination
                        totalRows={pageData.totalData}
                        pageChangeHandler={onCurrentPageChange}
                        rowsPerPage={7}
                    />
                </div>) : (<></>) }

            <Modal size="md" isOpen={sharepostmodal}>
                <ModalHeader className="center modalheader">
                    Share Post
                </ModalHeader>
                <ModalBody>
                    {usersharepost.id === "" &&
                        <div>
                            <Row>
                                <Col md="5">
                                    <label className="bold">
                                        Share Post
                                    </label>
                                </Col>
                                <Col md="7">
                                    <label>
                                        # {usersharepost.incidentname}
                                    </label>
                                </Col>
                            </Row>

                            <GridOfficerSharePost SelectUser={SelectUser} AgencyId={usersharepost.agencyId}></GridOfficerSharePost>
                        </div>
                    }
                    {usersharepost.id !== "" &&
                        <div>
                            <Row className="center">
                            <Row>
                                <Label className="lblinfo">With</Label>
                                </Row>
                            <Row>
                                <Col md="2">
                                </Col>
                                    <Col md="2" className="rowinfouser">
                                    <Avatar src={usersharepost.image} name={usersharepost.name} round size="50" />
                                    <label className="lblnameofficer mglb">  {usersharepost.name}</label>
                                    
                                       
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="rowmessageuser">
                                    <Col md="12">
                                    <Label className="lblinfo">Include a message for your colleague</Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <textarea className="form-control" onChange={ChangeMessage} style={{ minHeight: "4rem", marginLeft: "4%", resize: "none" }} ></textarea>
                                    </Col>
                                </Row>

                            </Row>
                        </div>
                    }

                </ModalBody>
                <ModalFooter className="footerright">
                   
                        <div>
                            <Button className="btn btn-secondary" onClick={toggleModal}>
                                Cancel
                        </Button>
                        {usersharepost.id !== "" &&
                            <Button
                                className="btn btn-primary"
                                color="primary"
                                disabled={usersharepost.message === ""}
                                onClick={() => SharedPost(usersharepost)}
                            >
                                Share   {loadingPostsShare && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                            </Button>

                        }
                        </div>
                    
                </ModalFooter>
            </Modal>
            <Modal size="md" isOpen={reportpostmodal}>
                <ModalHeader className="center modalheader">
                    Report Post
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <label className="subtitlemodal">Let us know what is wrong with this post</label>
                    </div>
                    <div onChange={ChangeOption}>
                    <div className="row radiorowoption">
                        <div className="col-1">
                            <input type="radio" name="option" value="Spam: Content that is irrelevant, promotional, or repeated multiple times." />
                        </div>
                        <div className="col-11">
                            <label className="optionradioreport">Spam: Content that is irrelevant, promotional, or repeated multiple times.</label>
                        </div>
                    </div>
                    <div className="row radiorowoption">
                        <div className="col-1">
                            <input type="radio" name="option" value="Impersonation: Accounts or posts pretending to be someone else, particularly public figures or celebrities." />
                        </div>
                        <div className="col-11">
                            <label className="optionradioreport">Impersonation: Accounts or posts pretending to be someone else, particularly public figures or celebrities.</label>
                        </div>
                    </div>
                    <div className="row radiorowoption">
                        <div className="col-1">
                            <input type="radio" name="option" value="Misinformation or False News: Content that spreads false or misleading information, particularly in cases where it could cause harm." />
                        </div>
                        <div className="col-11">
                            <label className="optionradioreport">Misinformation or False News: Content that spreads false or misleading information, particularly in cases where it could cause harm.</label>
                        </div>
                    </div>
                    <div className="row radiorowoption">
                        <div className="col-1">
                            <input type="radio" name="option" value="Other" />
                        </div>
                        <div className="col-11">
                            <label className="optionradioreport">Other</label>
                        </div>
                        </div>
                        </div>
                    <div className="row">
                        <label className="textinf">Provide information why do you report this post</label>
                    </div>
                    <div className="row">
                        <textarea className="messagetextarea" value={messageReport} onChange={ChangeMessageReport} disabled={optionReport!=="Other"}></textarea>
                    </div>
                    <div className="row" style={{paddingLeft: "15px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        <div style={{width: "15px", marginTop: "11px"}}>
                            <input type="checkbox" 
                            className="form-check-input nomt" 
                            id="isAnonymous" v
                            alue={isAnonymous} 
                            checked={isAnonymous} 
                            onChange={ToggleAnonymous}
                            />
                        </div>
                        <div style={{flex: 1}}><label className="form-check-label" htmlFor="isAnonymous">Anonymous</label></div>
                    </div>
                </ModalBody>
                <ModalFooter className="footerright">

                    <div>
                        <Button className="btn btn-secondary btn-cancel-modal" onClick={toggleModalReport}>
                            Cancel
                        </Button>
                        
                            <Button
                                className="btn btn-primary"
                                color="primary"
                                disabled={optionReport === "" ||( optionReport === "Other" && messageReport==="" )}
                                onClick={() => ReportPost(usersharepost)}
                            >
                                Create   {loadingPostsReport && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                            </Button>

                        
                    </div>

                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={imageModal}>
                <ModalBody className="PreviewImage">
                    {selectedImagePreview &&
                        <img src={selectedImagePreview.file.path} width="100%" height="100%" alt={selectedImagePreview.file.name || "" } />
                    }
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-secondary btn-cancel-modal" onClick={toggleModalImage}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default CardPost;